import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
  priScore?: number;
  proScore?: number;
  ind_PriScore?: number;
  ind_ProScore?: number;
  job_PriScore?: number;
  job_ProScore?: number;
  rev_PriScore?: number;
  rev_ProScore?: number;
}

const ResultsChart = ({
  priScore,
  proScore,
  ind_PriScore,
  ind_ProScore,
  job_PriScore,
  job_ProScore,
  rev_PriScore,
  rev_ProScore,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => {
  const posMax = 660;
  function relativePos(num: number, max: number) {
    return Math.round((max * num) / 100);
  }
  const priX = relativePos(priScore as number, posMax);
  const priY = posMax - relativePos(proScore as number, posMax);
  const indX = relativePos(ind_PriScore as number, posMax);
  const indY = posMax - relativePos(ind_ProScore as number, posMax);
  const jobX = relativePos(job_PriScore as number, posMax);
  const jobY = posMax - relativePos(job_ProScore as number, posMax);
  const revX = relativePos(rev_PriScore as number, posMax);
  const revY = posMax - relativePos(rev_ProScore as number, posMax);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 800"
      xmlSpace="preserve"
      {...props}
    >
      <g id="Topo_Lines" className="hidden">
        <g>
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,520.9c4.9-1.3,9.3-1.9,13.4-1.9 c12.2,0,21.2,5.6,34.8,14.1c8.8,5.5,19.8,12.3,34.8,19.5c14.4,6.9,24.8,9.6,36.8,9.6c14.4,0,31.6-3.9,60.2-10.4 c10.6-2.4,22.7-5.2,36.8-8.2c3.8-0.8,8-1.2,12.6-1.2c14.8,0,34,4.8,41.2,15.3c3.2,4.6,3.7,9.9,1.5,15.7 c-4.3,11.5-24.5,21.6-42.3,30.6c-10.8,5.4-20.9,10.5-26.1,15.3c-15.3,13.9-11.6,32.7-5.4,52.9"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,251.1c5.1,3.3,10.3,6.8,15.6,10.4 c14.7,10.2,24.1,18.4,36.1,28.7c2.7,2.3,5.6,4.8,8.6,7.4c5,4.3,7.7,9.2,8,14.7c0.5,9-5.6,16.5-8.3,19.3l-1.7,1.9 C57.7,360.2,28.8,393.4,26.7,439c-0.2,4.9-2.1,7.8-4.2,8.8"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M239.8,671.9c-2.8-11.5-1.2-19.9,12.7-35.1 c3.8-4.1,15.1-13.1,24.1-13.1c2.8,0,5.1,0.9,6.9,2.7c3.1,3.1,4.6,8.8,4.5,17c0,10-1.7,19.2-3.9,28.5"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,239c6.8,4.2,14,8.9,21.4,14 c18.4,12.8,36,27.4,52.3,43.1c14.8,14.4,8.6,23.4,5.2,28.2c-2.8,4-6.5,8.7-11.2,14l-1.7,1.9c-22.2,25.5-49.7,57.1-51.7,99.1 c-0.9,19.5-4.3,30.8-10.8,35.7c-1.3,1-2.5,1.5-3.6,1.6"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,188.6c7.6,5.7,15.5,11.3,24.5,15.9 c14.9,7.7,30.4,11.6,46,11.6l0,0c27.4,0,56.1-11.5,90.1-36.3c24.9-18.1,35.4-30.6,33.2-39.5c-2.8-11.3-25.8-14.3-52.5-17.8 c-6.6-0.9-13.4-1.8-20-2.8C114.8,115,88.1,100.3,62.2,86c-12.9-7.1-26.2-14.4-39.7-20.4"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,275.7c1.5,1.1,2.8,2.1,3.9,2.8 c2.2,1.6,4.5,3.1,6.9,4.7c1.3,0.9,2.7,1.8,4,2.7c14.4,9.6,28,18.6,21.1,38.1c-4,11.1-11.3,20.2-18.4,29.1 c-5,6.1-10.1,12.5-14.1,19.5c-1.2,2.1-2.3,4.8-3.4,7.7"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M305.6,671.9c8.5-26.5,26-42.5,56.3-52.6 c2.5-0.8,5.1-1.6,7.7-2.4c18.3-5.2,35-8.4,51-9.7c2.6-0.2,5.5-0.3,8.7-0.3c16.4,0,37.3,2.8,47.3,9.2c5.4,3.4,8.4,7.6,8.9,12.3 c1.4,12-12.5,26-23.6,37.2c-2.2,2.3-4.3,4.4-6.2,6.3"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,559.6c5-4,10.9-6.5,17.6-6.5 c5.2,0,10.3,1.6,15.4,4.8c9.4,5.9,21.1,13.2,37.5,21c15.5,7.4,29.7,11.7,43.3,13.1c1.6,0.2,3.3,0.3,5.2,0.6 c6.5,0.8,14.7,1.7,22,1.7c5.7,0,10-0.5,13.2-1.7c2.9-1,5-3,7.1-5c2.5-2.4,4.9-4.6,8.5-5.3c0.7-0.1,1.4-0.2,2.1-0.2 c2.1,0,3.9,0.5,5.8,1.1c1.8,0.5,3.7,1.1,5.8,1.1c1,0,2-0.1,3-0.4c3.2-0.9,6-3.6,8.7-6.1c2.2-2.1,4.4-4.2,6.9-5.4 c3.9-1.9,7.4-2.9,10.1-2.9c3,0,4.9,1.1,5.5,3.2c0.9,3.2-1.8,9-9.9,14.1c-3.1,2-6.6,2.2-9.3,2.2c-1.2,0-2.4-0.1-3.6-0.1 c-1.1-0.1-2.3-0.1-3.4-0.1c-2.4,0-5.2,0.2-7.7,1.8c-2.7,1.7-4.8,6-6.8,10.2c-1.2,2.5-2.4,4.9-3.6,6.6c-10,13.7-10.6,28.5-8.5,46.5 c0.8,6.2,2,12.3,3.5,18.2"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M252.4,98.8c-2.5,0-6.1,1.6-8.7,9.3 c-2.9,8.7,2.7,10.7,8.7,12.8c1.1,0.4,2.1,0.8,3.2,1.2c2.4,1,4.4,1.4,5.9,1.4c1.7,0,3-0.6,3.7-1.7c2.2-3.6-1.4-11.5-3-14.7 C259.5,101.9,255.8,98.8,252.4,98.8L252.4,98.8L252.4,98.8z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,262.9c1.4,1,2.7,2,3.9,2.9 c2.3,1.7,4.3,3.2,5.8,4.2c2.2,1.5,4.4,3,6.8,4.7c11.4,7.8,24.3,16.5,32.9,27.1c10.9,13.5,1.2,24.9-8.1,35.9 c-0.7,0.8-1.3,1.6-2,2.4c-8.2,9.8-18.7,23-27,37.6c-5,8.9-9.2,18.2-12.4,27.8"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M261.5,671.9c-0.1-0.7-0.2-1.5-0.5-2.5 c-0.6-2.4-1.7-4-2.8-5.6c-1.6-2.5-3-4.6-2.5-8.6c0.8-5.5,7.4-19.1,14.5-19.1h0.2c3.6,0.1,5.9,1,6.8,2.7c1.7,3-1.3,8.2-3.7,12.3 c-1.3,2.2-2.4,4.2-2.6,5.4c-0.4,2.5,0.7,3.9,1.8,5.3c0.9,1.1,1.6,2.1,1.6,3.7c-0.1,3.2-1.5,4.7-3,6.3c-0.1,0.1-0.1,0.2-0.2,0.2"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,169.9c1.1,0.8,2.2,1.6,3.2,2.4 c8.8,6.7,17.9,13.6,28,18.9c11.1,5.7,20.6,8.4,29.8,8.4s18.2-2.9,28.1-6.3c5.6-1.9,12-4.1,19.2-5.9c3.7-0.9,6.9-1,8.9-1 c1.4,0,2.7,0.1,4,0.1c1.2,0,2.4,0.1,3.5,0.1c5.9,0,10.7-1.4,15.9-8.4c3.5-4.7,4.8-8.4,5.9-11.6c1.7-4.8,3.1-8.6,10.7-14.1 c1.9-1.4,4.7-2.5,7.4-3.6c4.3-1.7,8.1-3.2,8.2-5.7c0.1-2-2.2-4.3-7.4-7.1c-6.2-3.4-15.1-3.8-23-3.8c-3.5,0-7.2,0.1-10.8,0.2 c-3.6,0.1-7.3,0.2-10.8,0.2c-7.9,0-13.4-0.6-17.9-1.8c-25.5-7-49.4-20.1-70.6-31.8C44,93,33.2,87.1,22.5,82"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M352.5,671.9c5.3-6,12.5-10.3,22.3-13.6 c1.9-0.7,3.9-1.3,6-1.8c0.2-0.1,18.1-5.1,26.6-5.1c1.4,0,2.4,0.2,3.1,0.4c3.2,1.4,2.1,5.1-0.6,13.3c-0.6,2-1.4,4.3-2.1,6.8"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M369.5,671.9c2.5-1.3,5.4-2.6,8.6-3.6 c1.8-0.6,3.6-1.2,5.5-1.7c2.8-0.8,5.1-1.2,7.1-1.2c3.6,0,5.9,1.3,6.6,4c0.2,0.8,0.2,1.6,0.2,2.5"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M339.9,671.9c6.7-10.9,17-18.4,31.7-23.3 c2.1-0.7,4.2-1.4,6.4-2c16.4-4.7,31.2-7.5,45.2-8.7c1.6-0.1,3.1-0.2,4.4-0.2c4.9,0,7.8,0.9,8.7,2.8c1.6,3.3-3,9.4-7.4,15.4 c-0.8,1-1.6,2.1-2.4,3.2c-3.1,4.2-5.9,8.5-8.3,12.9"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M328.2,671.9c7.7-16,20.8-26.7,40.2-33.1 c2.2-0.8,4.6-1.5,6.9-2.1c17-4.8,32.5-7.8,47.2-9c5.8-0.5,10.7-0.7,15.1-0.7c12.8,0,20.1,2.1,21.8,6.1c1.3,3.4-1.3,8.3-8,14.5 c-1.3,1.2-2.6,2.5-3.7,3.6c-5.1,5.1-9.4,9.5-12.7,14c-1.6,2.2-3.1,4.5-4.6,6.8"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,99c8.2,4.2,16.6,8.8,25.2,13.5 c22.7,12.5,42.6,21.7,62.7,29.1c1.4,0.5,3.5,1.1,6,1.8c11.1,3.2,29.6,8.6,29.6,18c0,14.3-40.2,24.5-53,24.5 c-10.9,0-21.5-2.7-32.3-8.2c-9-4.6-17.5-11.1-25.8-17.4c-4-3.1-8.1-6.2-12.4-9.2"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,110.6c6.6,3.4,13.4,7.1,20.2,10.9 l1.3,0.7c12,6.6,24.5,13.5,37,19.3c1.9,0.9,4.9,1.9,8.5,3.1c10.2,3.4,25.5,8.7,27.3,15.6c0.8,2.9,0.3,5.5-1.4,7.7 c-4,5.2-14.5,7.6-22.5,7.6c-9.3,0-18.3-2.3-27.6-7.1c-8.2-4.2-16.3-10.4-24.3-16.5c-5.7-4.4-11.9-9-18.6-13.5"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M239,9c0.9,3.6,2.1,7.4,3.6,11.7 c1,2.9,2.1,6.2,3.2,9.7c4.5,14.6,3.8,28.9-2.1,42.7c-4.1,9.7-10.4,16.2-17,23.1c-3.4,3.6-6.9,7.2-10.2,11.4 c-2.7,3.4-3.6,7-2.5,10.5c3.1,10.6,23.1,19.3,39.2,24.7c14.8,5,34.7,10,46,10c3.4,0,5.8-0.4,7.3-1.3c3.1-1.9,4.8-4,5.1-6.3 c0.6-5-5.3-10.6-12.1-17.1c-7.4-7.1-15.8-15.2-19.2-24.9c-8.3-24.3-5.1-49.4-1.4-68.5c1.1-5.7,2.5-11.4,3.9-16.8 c0.7-3,1.5-5.9,2.1-8.8"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M161.3,362.4c-0.7,1.2-1.4,2.4-2,3.2 c-7.4,10.5-16.2,19.8-26.1,30c-1.3,1.4-2.7,2.8-4.1,4.2c-8.2,8.3-16.6,16.8-21.6,27c-1.9,3.9-2.6,7-1.7,8.9c0.4,0.9,1,1.4,1.9,1.7 c0.4,0.1,0.8,0.2,1.2,0.2c1.8,0,4.2-0.9,6.9-2.5c5.4-3.3,10.6-7.4,15.6-11.3c4.1-3.2,8.3-6.6,12.6-9.5 c17.6-11.7,29.1-19.4,34.6-41.2c1.1-4.5,1.8-14.8-1.7-19.3c-1.2-1.5-2.8-2.3-4.7-2.2C167.7,351.7,164,357.9,161.3,362.4 L161.3,362.4L161.3,362.4z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,122.4c5,2.7,10.1,5.5,15.2,8.3l1.3,0.7 c12.2,6.7,24.8,13.6,37.7,19.7c1.1,0.5,3.1,1.1,5.3,1.7c4.9,1.4,11.7,3.3,12.1,5.9c0.2,1.1-0.8,2.3-2.8,3.6 c-0.9,0.6-2.4,0.9-4.4,0.9c-5.8,0-14-2.7-16.8-4.1c-7.3-3.8-15.2-9.7-22.7-15.5c-7.2-5.5-15.4-11.8-24.9-17.5"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,227.1c8.8,5.2,18,11.1,27.2,17.6 c27.4,19.1,47.7,38.2,60.5,51c11.4,11.3,12.3,21.1-12.3,49.5l-1.7,1.9c-21.1,24.3-47.4,54.5-49.2,92.9 C45.5,476,32,486.6,22.6,489.6"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M316.9,671.9c8.5-21,24.1-34.9,48.2-42.9 c2.4-0.8,4.8-1.5,7.3-2.2c17.7-5,33.8-8.1,49.1-9.4c5.5-0.5,10.8-0.7,15.6-0.7c13.7,0,31,1.9,35.2,10.9c4.6,9.9-6.6,20.5-14,27.5 c-1.3,1.2-2.5,2.3-3.5,3.3c-4.8,4.8-8.9,9-11.7,12.8c-0.2,0.2-0.3,0.4-0.5,0.7"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,574.4c5.1-6.9,11.4-11.1,17.6-11.1 c3.2,0,6.6,1.1,10,3.2c9.6,6,21.6,13.5,38.5,21.5c15.6,7.5,31.4,11.8,49.9,13.5c3.9,0.4,7.9,0.4,11.9,0.5 c6.8,0.1,13.7,0.2,20.2,1.9c10.1,2.6,9.7,10,8.3,18.7c-1.7,10.4-3.1,20.6-1.7,32.3c0.7,5.8,1.8,11.5,3.1,16.9"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,29.8c0.9,0.5,1.8,1.1,2.7,1.6l1.2,0.7 c34.3,21,98.1,59.9,144.6,59.9c5.1,0,9.9-0.5,14.2-1.4c5.1-1.1,9.9-2.9,14-5.3c25.4-14.8,21.3-32,11.1-63.9 c-1.3-4-2.6-8.2-3.9-12.5"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M97.2,9c5.4,2.9,11.1,5.8,16.8,8.6l6,3 c1.1,0.6,2.6,1.3,4.5,2.4c3.9,2.1,15.9,8.6,23.2,8.6c1.8,0,3.2-0.4,4.2-1.2c1.9-1.6,2.2-4.6,0.9-9.2c-0.5-1.5-0.9-2.9-1.2-4 c-0.7-2.7-1.4-5.4-2.1-8.1"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,12.7c1.9,1,3.7,2,5.5,3.1l6.1,3.7 c33,20.2,94.5,57.8,136.9,57.8c4,0,7.8-0.4,11.1-1.1c7.5-1.6,12.9-5.1,16-10.5c6.2-10.7,2.3-26.8-1.8-39.6 c-1.8-5.5-3.5-11.2-5-17.1"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M159.6,9c0.5,1.9,1,3.8,1.4,5.6 c0.3,1,0.6,2.2,1.1,3.6c2.1,7,5.4,17.6,2.3,22.5c-1.2,2-3.2,3.3-5.7,3.9c-1,0.2-2.2,0.3-3.4,0.3c-10.7,0-26.8-8.7-35.4-13.3 c-1.7-0.9-3.1-1.7-4.2-2.2l-0.8-0.4c-0.8-0.4-1.6-0.8-2.4-1.2l-2.8-1.4C98.1,20.8,87,14.9,76.8,9"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M55.8,9c14.3,9,31.1,18.2,49.2,27.1l2.8,1.4 c0.9,0.5,1.9,1,3.1,1.5c11.5,5.8,32.9,16.5,47.9,16.5c1.5,0,2.9-0.1,4.2-0.3c8.8-1.4,13.2-7.8,12.9-18.9 c-0.2-8.4-2.4-16.6-4.5-24.5c-0.2-0.9-0.5-1.9-0.8-2.8"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M36.8,9c1.4,0.9,2.7,1.6,2.7,1.6 C57.9,22,78.8,34.7,100.5,45.4l2.8,1.4c16.9,8.3,40,19.8,59.8,19.8c1.9,0,3.7-0.1,5.5-0.3c0.7-0.1,1.4-0.2,2.2-0.4 c7.1-1.6,13.8-6.5,17.1-12.5c4-7.3,1.6-14.8-0.8-22.1c-0.2-0.7-0.4-1.4-0.7-2c-2.1-6.5-4.1-13.2-5.8-20.2"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M170.1,337.9c-6.4,1.6-12.1,10.5-16.6,17.7 c-1.6,2.5-3.1,4.9-4.4,6.6c-7.3,9.4-15.5,17.9-23.4,26.1c-2.1,2.1-4.7,4.6-7.4,7.1c-8.2,7.6-18.4,17.1-21.5,25.8 c-2.6,7.3-1.1,14.3,0.4,21c1.2,5.5,2.5,11.2,1.5,17.2c-0.6,3.7-2.8,7.3-5.2,11.1c-2.8,4.6-5.7,9.3-5.9,14.5 c-0.1,1.7,0.2,3.5,0.8,5.1c1.9,5.5,4,8,6.4,8s5-2.5,8.8-8.6c3.7-5.8,5.1-12.5,6.5-19.1c1.4-6.5,2.8-13.3,6.5-19.1 c7-11.1,19.4-19.4,30.4-26.6c0.9-0.6,1.8-1.2,2.7-1.8c18.2-12.1,32.5-21.6,38.9-47.4c1.7-6.8,1.2-24.2-5.6-33 C179.8,338.1,175.4,336.6,170.1,337.9L170.1,337.9L170.1,337.9z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M176.9,305.9c-3.5-5-6.5-5.7-9.8-6.4 c-2.2-0.5-4.5-1-7.1-3l-0.2-0.2c-7.5-8.4-32.6-35.8-41.1-35.8c-1,0-1.8,0.4-2.3,1.1c-1.1,1.5-1.1,4.5,0.2,9.1 c0.8,2.8,7.8,6.6,15.4,10.7c6.3,3.4,12.7,6.9,14.9,9.5c2,2.4,4.6,5.6,4.3,6.7c-0.1,0.3-0.4,0.5-1,0.8c-1.3,0.6-3.3,1.6-2.2,6.3 c0.7,3.2,2.7,6.4,4.6,9.5c1.8,2.9,3.5,5.6,4.1,8.2c1.9,7.2-9.9,20.5-17.8,29.3c-2.6,2.9-4.8,5.4-6.2,7.3 c-5.7,7.6-12.8,14.6-19.7,21.4c-13.4,13.2-27.2,26.9-32,45.8c-7.3,28.2-8.7,53.1-4.3,74c2.3,11.2,10.4,26.7,18.3,26.7 c4.9,0,8.4-5.9,10.6-17.5c8.2-44.8,30.6-59.7,52.3-74.1c19.2-12.8,37.3-24.8,45.1-56.2c2.9-11.8-1.6-30-7.5-40.1 c-2.6-4.5-5.9-7-9.1-9.5c-2.8-2.1-5.4-4.2-7.5-7.4c-1.4-2.3-1.2-5-0.9-7.8C178.3,311.4,178.6,308.4,176.9,305.9L176.9,305.9 L176.9,305.9z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,210.3C72,238.4,125.3,283.1,135,305.3 c5.8,13.4-8.3,29.3-26.2,49.5c-20.3,23-45.6,51.5-47.2,85.8c-1.9,41.8-22.5,59-39.1,64.1"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M261,9c0.3,2.6-0.6,4-1.7,5.6 c-0.9,1.4-2,3-2.4,5.7c-0.6,3.7,0.7,5.7,2,7.9c0.9,1.5,1.9,3.1,2.3,5.4c2,11.5-3.3,32.8-8,43.7c-4.9,11.4-12.1,18.9-19.1,26.2 c-4.6,4.8-6.4,9.1-5.4,13c2,8,15.4,12.5,25.3,15.7c0.8,0.3,1.6,0.5,2.4,0.8c2.7,0.9,5.4,2.1,8,3.2c4.5,2,9.2,4,14.1,4.8 c2.6,0.4,4.9,0.6,6.7,0.6c3,0,5-0.6,6-1.7c0.6-0.7,0.9-1.5,0.8-2.6c-0.2-4.3-6.2-11.2-12-17.8c-4.5-5.1-8.7-10-9.8-13.1 c-5.2-15.2-6.9-32.8-5-52.2c0.3-3.2,1-6.5,1.6-9.6c0.8-3.9,1.5-7.9,1.7-11.7c0.1-2.5-0.2-5-0.5-7.4c-0.5-4.5-1-8.7,0.8-12.9 c0.7-1.6,1.6-2.6,2.6-3.6c0,0,0-0.1,0.1-0.1"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,47.2C60.9,70.6,122,107,171,107 c6.1,0,11.9-0.6,17.3-1.7c6.7-1.4,12.8-3.8,18.4-7c36.9-21.5,27.2-51.9,17.8-81.3c-0.9-2.7-1.7-5.3-2.5-8"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,597.2c1.5-3.2,2.9-6.4,4.3-9.7 c3.4-7.8,8.9-12.4,14.7-12.4c2.5,0,5.1,0.9,7.7,2.6c9.5,6.6,20.6,12.9,34.9,19.7c16.7,8,33.6,12.6,53.4,14.5 c1.7,0.2,3.4,0.2,5.2,0.3c7,0.4,14.9,0.8,20.1,5.6c6.1,5.6,5.2,16.3,4.4,25.7c-0.4,4.6-0.7,9-0.3,12.6c0.6,5.3,1.9,10.6,3.2,15.7"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,642.1c1,0.5,2.2,1,3.4,1.5 c0.6,0.2,1.2,0.5,1.9,0.8c9.4,4,16.6,7.8,22.7,11.8c7.6,5.1,16.1,10.4,25.4,15.7"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,653.3c8.5,3.5,17.1,8,22.2,11.5 c3.5,2.4,7.2,4.8,11.1,7.2"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,300.8c1.2,0.8,2.3,1.6,3.5,2.3 c5.9,3.9,9.1,8.1,9.8,12.7c1.2,7.6-4.6,15.5-11.7,24.3c-0.5,0.6-1,1.3-1.5,2.1"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M37.6,671.9c-3-1.8-3.8-2.3-4-2.5l0,0 c-3.5-2.2-7.2-4.1-11-5.8"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M119.6,9l1.8,0.9c0.3,0.2,0.7,0.4,1.1,0.6 c2.8,1.4,6.5,3.3,9.8,3.3c1.4,0,2.6-0.3,3.6-1c0.1-0.1,0.2-0.1,0.2-0.2c1.1-0.9,1.8-2.1,2.2-3.6"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M129.4,658.6c0.5-1.9,0.4-3.6-0.5-4.9 c-2.2-3.3-8-4.2-12.2-4.8c-0.7-0.1-1.4-0.2-1.9-0.3c-0.2,0-0.5-0.1-0.9-0.2c-2.2-0.4-6.8-1.4-10.3-1.4c-2.1,0-3.4,0.3-4,1 c-0.3,0.3-0.5,0.8-0.4,1.3c0.2,3.8,6,6.2,9.5,7.6c0.5,0.2,1,0.4,1.4,0.6c0.9,0.4,2,0.9,3.1,1.5c3.6,1.8,7.6,3.8,11.1,3.8 c0.4,0,0.9,0,1.3-0.1C127.5,662.4,128.8,661,129.4,658.6L129.4,658.6L129.4,658.6z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,619.4c1.6-2.9,3-6.4,4.3-10 c2.7-7.3,5.6-14.8,11.4-18.6c1.6-1.1,3.3-1.6,5.2-1.6c3.6,0,7.2,1.9,10.7,3.8l0.2,0.1c3,1.6,6,3.3,8.9,4.9c5.3,3,10.8,6,16.4,8.7 c17.8,8.5,35.8,13.5,56.8,15.5c1.9,0.2,3.9,0.3,5.6,0.4l0.5,0c4.8,0.2,10.2,0.5,13.5,4.5c2.7,3.3,2,7.6,1.3,11.9 c-0.2,1.4-0.4,2.7-0.5,3.9c-0.4,4.9-0.8,10-0.2,14.6c0.6,5,1.7,9.8,2.8,14.5"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,288.3c1.6,1.1,3.3,2.2,5,3.4 c1.4,0.9,2.8,1.9,4.1,2.8c17,11.3,20.8,15.6,17.1,26c-3.4,9.5-9.8,17.5-16.7,26c-3.2,4-6.5,8-9.5,12.3"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M98.2,671.9c-15.7-8.3-29.9-16.7-41.7-24.7 c-4.7-3.2-9.9-6.1-15.9-9c-0.7-0.3-1.4-0.6-2.2-1c-3.4-1.4-7.7-3.2-8.7-6.4c-1-3.1,2-8.6,4.3-13c1.1-1.9,2-3.6,2.5-4.9 c0.3-0.7,0.5-1.5,0.8-2.4c1.1-3.3,2.3-7,4.9-8.8c0.9-0.7,2-1,3.2-1c3.4,0,7.2,2.5,10.3,4.5c1,0.6,1.9,1.2,2.7,1.6 c5.4,3,11.1,6.2,17,9c12.8,6.1,26.2,10.7,40.1,13.5c2.8,0.6,5.7,0.8,8.5,1.1c4.8,0.5,9.9,0.9,14.4,3c8.1,3.5,8,10.5,8,17.8 c0,2.4,0,4.9,0.3,7.3c0.6,4.7,1.4,9,2.5,13.2"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M121.2,671.9c-0.8-0.4-2.4-1.2-2.4-1.2 c-21.7-10.7-41.3-21.8-56.6-32.1c-2-1.4-4.2-2.5-6.3-3.6c-2.9-1.5-5.9-3.1-8.5-5.3c-3.5-3-4.4-5.9-2.9-9.5 c1.2-2.8,3.1-4.2,5.9-4.2c4.8,0,11.1,3.9,15.7,6.7c1.8,1.1,3.4,2.1,4.6,2.7c13.5,6.5,27.8,11.3,42.5,14.3c3.3,0.7,6.5,1,9.5,1.3 c0.5,0,1.1,0.1,1.6,0.1c3.7,0.3,7.9,0.6,10.3,3.4c2.5,2.8,2.7,8.3,2.9,12.8c0.1,1.2,0.1,2.4,0.2,3.4l0,0.6c0.3,3,0.7,7.3-0.4,10.5 "
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M164.9,376.2h-0.1c-2.1,0-4.2,2.6-5.7,4.6 c-0.3,0.4-0.6,0.8-0.9,1.1c-1.4,1.7-2.9,3.4-4.2,5.1c-0.9,1.1-1.7,2.2-2.6,3.3l-0.1,0.1c-2.9,3.5-4.1,5.8-3.6,6.9 c0.1,0.3,0.4,0.7,1.2,0.8c0.1,0,0.2,0,0.4,0h0.1c5.9-0.1,10.6-5.5,12.9-8.6c2.2-3,3.8-6.3,4.3-9c0.1-0.6,0.5-2.7-0.4-3.8 C165.8,376.4,165.4,376.2,164.9,376.2L164.9,376.2L164.9,376.2z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M319.5,525.4c19.5-2.2,40.3-24.9,40.6-44 c0.4-29.4-16.4-52.9-32.7-75.6c-2.8-3.9-5.6-7.9-8.3-11.8l-1.6-2.4c-7.6-11.1-16.3-23.6-19.2-37.2c-2.4-11.5-6-38.3-2.1-47.5 c2.9-6.8,11.2-10.6,19.2-14.4c5.5-2.6,10.8-5,14.5-8.4l0.2-0.2c8.4-7.6,15.4-11.5,21.2-11.7c2.6-0.1,5.8-0.2,10.4-0.6 c6.1-0.6,9.7-1.5,12.4-2.1c1.7-0.4,2.9-0.7,4-0.7c2.6,0,4.9,1.9,12.2,8.1c21.6,18.4,21.2,50.4,20.8,81.3 c-0.5,38.9-1,79.2,42.9,91.9c6.3,1.8,12.5,2.8,18.2,2.8c16.5,0,30.5-7.4,40.6-21.4c14.8-20.5,18.7-52.6,9.8-79.8 C501.9,289,452.6,253.2,399.5,219c-48.2-31.1-94.1-39.6-133.3-45.2c-2.8-0.4-5.5-0.6-8-0.6c-15.9,0-23.5,8.1-33.1,18.4 c-3.7,3.9-7.5,8-12.2,12.2c-5.4,4.8-12.2,8-18.9,11.2c-10.8,5.1-21,10-25.3,20.8c-9,22.9,13.1,40.7,32.5,56.5 c9,7.3,17.5,14.2,22.5,21c19.7,27.3,13.8,73.3-4.1,99.1c-5.6,8.1-16.3,18-27.6,28.6c-18.8,17.5-40,37.3-43.8,54 c-1.8,7.8-1.3,13.3,1.4,16.7c2.3,2.9,6.1,4.2,11.7,4.2c9.2,0,22.4-3.8,36.4-7.9c17.1-4.9,36.4-10.5,53-10.5 c7.9,0,14.4,1.2,19.9,3.8c6.4,2.9,11.8,7.1,17,11.2c8.6,6.7,16.7,13.1,28.2,13.1C317.1,525.7,318.3,525.6,319.5,525.4L319.5,525.4 L319.5,525.4z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M674.4,9c2.7,1.1,5.3,2.3,7.9,3.7"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M662.4,298.6c-0.8-0.5-1.9-1.5-3.3-2.8 c-5.6-4.9-14.9-13.1-21.5-13.1c-2.6,0-4.5,1.2-5.7,3.7c-1.5,3.1,1.8,10.3,4.1,15c0.5,1.2,1,2.2,1.3,2.9c0.6,1.5,1.2,2.8,1.6,4.1 c3.1,8.2,6.3,16.6,8.7,25.8c0.2,1,0.5,2,0.8,3.2c1.1,4.9,2.5,11,5.8,14c1.3,1.2,2.6,1.9,3.8,2.3c0.6,0.2,1.1,0.2,1.6,0.2 c4,0,6.7-4.5,9.4-9c0.6-1,1.2-2,1.8-3l0.6-0.9c10.1-15.5,16.2-24.8-2.4-37.7C667.2,302.1,664.8,300.4,662.4,298.6L662.4,298.6 L662.4,298.6z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,358.9c-1.9,2.7-3.8,5.6-5.5,8.6 c-1,1.8-1.9,3.5-2.6,5c-2.4,4.9-4,8.1-9.2,9.8c-1.6,0.5-3.5,0.9-5.7,1.1c-1.8,0.2-3.5,0.3-5.1,0.3c-19.8,0-21.5-13.5-23.5-29.2 c-0.6-5-1.3-10.1-2.5-15c-2.1-8.1-5.1-16.1-8.1-23.8c-2.5-6.6-5.1-13.4-7.1-20.2c-3.2-11.1-5.9-30.8,12.5-37.8 c2-0.7,3.9-1.1,5.9-1.1c10.5,0,22.3,9.7,32.7,18.2c3.4,2.8,6.7,5.5,9.9,7.8c2.5,1.8,4.6,3.4,6.2,4.5c0.7,0.5,1.3,0.9,2,1.4"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,663.7c-8.9-4-18.4-6.8-28.5-8.4 c-0.7-0.1-1.5-0.2-2.3-0.3c-4.6-0.5-10.9-1.3-13-4.9c-2.2-3.6,1-7.3,3.5-10.4c0.4-0.4,0.7-0.8,1.1-1.3c5.9-7.3,13.6-17,20-27.1 c5.7-9,9.8-18.2,14.1-28.1c1.5-3.3,3.2-6.3,5.1-8.9"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,29c-0.6-0.4-1.2-0.8-1.8-1.1 c-13.5-8.2-29.1-12.2-47.7-12.2c-16.9,0-34.2,3.3-50.9,6.4c-16.5,3.1-32.1,6-46.9,6c-10.4,0-26.3-4.4-28.4-11.5 c-0.7-2.4,0.1-4.9,2.5-7.6"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,169c-16.9-12.8-34.8-25.1-60.1-25.1 c-7.8,0-16.1,1.2-24.5,3.7c-28.6,8.2-54.2,26.7-72,51.9c-3.7,5.2-6.8,10.6-9.5,15.4c-5.2,9.1-9.7,16.9-15.7,19.1 c-1.2,0.5-2.5,0.7-3.8,0.7c-5.7,0-12.5-4.2-21.6-13.3c-33.5-33.4-66.3-53.2-113.5-68.4l-1.4-0.4c-9.2-3-14-6.5-13.5-9.9 c0.7-4.7,12-9,23.5-9c3.7,0,7.4,0.1,9.7,0.4c4,0.4,9.5,1.5,15.4,2.7c11.2,2.2,23.8,4.8,36,4.8s22.1-2.6,29.8-8.1 c11.5-8.2,13.2-16.6,15.4-27.4c0.6-2.8,1.2-5.8,2-8.9c3-11.4,3.6-11.5,9.4-12.9c2.1-0.5,4.9-1.2,8.7-2.6c2.6-1,6.3-3,10.1-5.2 c5.6-3.2,12-6.8,15.7-7.2c0.6-0.1,1.1-0.1,1.7-0.1c6,0,10.3,3.4,15,7.1c4.3,3.4,8.7,6.9,14.7,7.7c1.3,0.2,2.7,0.3,4,0.3 c8,0,15-3.6,21.7-7c4.1-2.1,8.4-4.3,12.9-5.7c8.7-2.7,16.6-3.2,26.5-3.2c1.7,0,3.5,0,5.2,0c1.8,0,3.7,0,5.6,0c9,0,17,1.1,24.5,3.2 c9.5,2.7,18.9,6.5,28.2,11"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,652.8c-5-2-9.8-3.8-14-4.8 c-0.9-0.2-1.8-0.4-2.8-0.5c-4.1-0.7-8.3-1.5-8.5-5.9c-0.2-6,5.1-12.5,9.8-18.1c1.9-2.3,3.8-4.5,5-6.5c4.2-6.5,7.5-13.1,10.5-19.8"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,342.1c-1,1.5-2.2,3.2-3.5,5.2 c-6.2,9.5-14.8,22.6-23.1,22.6c-0.4,0-0.7,0-1.1-0.1c-1.2-0.2-2.3-0.6-3.5-1.3c-6.9-4.5-9.7-17-11.7-26.2c-0.5-2-0.9-3.9-1.3-5.6 c-2.3-8.7-5.4-16.9-8.4-24.8c-0.4-1.2-1-2.5-1.5-3.9c-4.6-11.3-12.2-30.1,0.3-38c1.6-1,3.5-1.5,5.4-1.5c7.3,0,15.3,6.8,21.2,11.8 c0.9,0.8,1.8,1.5,2.6,2.2c3.5,2.9,6.9,5.6,10.3,8.2c2.4,1.8,4.7,3.5,6.4,4.6c2.3,1.6,4.6,3.2,7.1,4.9c0.3,0.2,0.5,0.3,0.8,0.5"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,447.8c-0.5,0.2-1.1,0.4-1.6,0.4 c-1.8,0-3.3-1.3-4.3-3.5c-3.9-8.8-9.1-16.3-15.5-22.2l-0.2-0.2c-18.8-17.2-41-19-55.8-20.2c-3.7-0.3-5.2-1.7-6-2.7 c-1.9-2.5-2.1-6.2-1.9-7.4c2-9,4.6-20.2,3.6-32.5c-1.6-20.8-9.9-38.6-17.9-55.9c-6.4-13.9-13.7-29.6-11.2-36.7 c4.5-12.7,22.9-31,30.6-38.3c4.9-4.6,10.7-6.9,17.3-6.9c3.8,0,7,0.8,9.1,1.5c13.5,4.4,32.6,14.4,53.9,28.1"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,641.6c-2.8-1.4-4.7-3.4-5.3-7.8 c-0.6-4.6,2-9,4.3-12.8l0.4-0.7c0.2-0.3,0.3-0.6,0.5-0.9"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,46c-1.1-0.7-2.2-1.4-3.3-2 c-2.1-1.3-4.1-2.5-6-3.7c-11.2-6.8-24.3-10.1-40.1-10.1s-32.1,3.1-48.2,6.2c-16.4,3.1-33.4,6.3-49.6,6.3 c-17.5,0-32.1-3.8-44.5-11.7c-8.4-5.3-12.4-10.9-12-16.5c0.1-1.8,0.7-3.6,1.7-5.4"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,380.3c0,0,0,0.1-0.1,0.1 c-2.9,7.3-6.2,15.6-12.5,17.5c-0.2,0.1-0.4,0.1-0.6,0.1c-0.7,0.2-1.5,0.2-2.3,0.2c-4.2,0-9-2.3-13.1-4.4c-2-1-4-2-5.8-2.6 c-2.8-1-6.2-1.6-9.5-2.2c-4.4-0.8-8.9-1.5-12.2-3.3c-7.2-4-6.5-11.3-5.7-19c0.3-3,0.6-6.2,0.4-9c-1.9-24.5-11-44.1-19.7-63 c-4-8.6-4.3-18-0.8-26.5c3.7-9.2,11.6-16.6,21.5-20.4c3.1-1.2,6.3-1.8,9.6-1.8c14.2,0,27.4,10.9,39.2,20.5 c3.3,2.7,6.5,5.3,9.5,7.5c0.8,0.6,1.5,1.1,2.1,1.6"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,150.9c-16.1-11.4-34.8-21.6-60.1-21.6 c-9.2,0-18.8,1.4-28.5,4.2c-31.8,9.1-60.1,29.6-79.8,57.4c-6.6,9.4-12.9,14.3-19.8,15.6c-1.1,0.2-2.3,0.3-3.5,0.3 c-8.7,0-17.9-5.5-29.1-13.7c-1-0.8-2.6-1.8-4.3-3c-9.5-6.4-27.1-18.2-25.2-26.6c1.5-6.7,13-10,23.1-12.9 c5.9-1.7,11.5-3.3,14.4-5.4c8.5-6,12.4-16.2,16-26c2.1-5.5,4.2-11.2,7.1-15.9c6-9.7,11.5-13.7,19-13.7c3.2,0,6.5,0.7,10.3,1.4 c4.5,0.9,9.6,1.9,15.6,2c1.2,0,2.4,0,3.7,0c17.3,0,33.5-2.8,49.2-5.5c13.9-2.4,27-4.6,39.2-4.6c7.6,0,14.3,0.9,20.5,2.7 c10.7,3.1,21.3,7.7,32.2,13.2"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,405.5c-0.4,1.2-0.8,2.5-1.2,3.7 c-1.5,5.1-2.9,9-5.6,9.6c-1.9,0.4-4.4-0.8-7.8-3.9l-0.3-0.2c-13.3-12.1-26.6-15.2-42-18.8c-2.8-0.7-5.8-1.3-8.8-2.1 c-10.8-2.7-8.9-12.9-7.2-22.7c0.8-4.3,1.6-8.7,1.3-12.5c-1.7-22.6-10.4-41.4-18.8-59.5c-1-2.2-2.1-4.3-3.1-6.3 c-5.4-10.6-10-19.8-3.4-31.1c5-8.5,12.5-15.4,19.8-22.1c1.3-1.2,2.6-2.4,3.9-3.6c2.8-2.6,6.6-4,11.3-4c18.1,0,45.8,19.3,61.8,30.9 "
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M308.1,606.3c-3.7,5.6-8.3,12.6-6.7,17.4 c0.6,2,2.3,3.5,4.9,4.4c0.5,0.2,1,0.3,1.6,0.3c5.7,0,14.6-8.8,22.5-16.5c5-4.9,9.8-9.6,12.6-10.9c13.7-6.2,32.5-9.2,49.1-11.8 c6.6-1,12.8-2,18.5-3.1c13-2.6,25.6-3.9,37.5-3.9c38.6,0,71,14,96.4,41.5c2.8,3.1,7,4.7,12.3,4.7c16.5,0,44.9-16.2,64.9-46.2 c12-17.9,29.3-54.6,11.3-98.6c-7.2-17.6-18-25.9-33.8-25.9c-12,0-25.8,4.5-41.7,9.6c-19.6,6.4-41.8,13.5-65.7,13.5 c-18.6,0-35.7-4.3-52.2-13.2c-51-27.3-52.5-77.2-48.1-117.6c1.7-15.7-1.1-28.1-8-36c-4.9-5.5-11.6-8.4-19.4-8.4 c-14.7,0-33.1,11.5-39.7,33.4c-6.2,20.6,8.5,39.4,25.7,61.2c19.3,24.5,41.2,52.3,36.9,89.4c-2.1,18.4-8.8,36-17.9,47.2 c-6.9,8.5-13,9.2-19.4,10c-4.9,0.6-10,1.2-16,5c-4.6,2.9-6,7.8-7.5,13.1c-1,3.6-2.1,7.3-4.3,10.4c-1.2,1.7-3.3,2-5.5,2.4 c-2.9,0.5-6.2,1.1-8,4.9c-1.2,2.6,0.4,5.7,2,8.8c1.3,2.5,2.6,5.1,2.2,7.2C312.1,600.3,310.3,603.1,308.1,606.3L308.1,606.3 L308.1,606.3z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M22.5,536c5.3-1.7,9.7-2.6,13.4-2.6 c7.7,0,13.9,3.7,27.2,11.9c9.1,5.7,20.5,12.7,36.1,20.2c16.3,7.8,28.8,11,43.1,11c16,0,33.9-4.1,63.4-10.8 c3.5-0.8,7.4-1.9,11.5-3.2c8.9-2.6,18.9-5.6,27.8-5.6c3.4,0,6.3,0.4,9,1.3c6.7,2.1,10.3,5.4,10.6,9.8c0.7,8.2-10.5,18.4-18.8,22.5 c-12.2,6.1-22.7,11.4-29.4,17.5c-20.2,18.3-16.9,42.2-10.8,63.7"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,209.2c-25.9-14.7-50.7-24.8-67.7-24.8 c-1.8,0-3.5,0.1-5,0.3c-26.4,3.6-63.5,46.2-71.9,70c-7.4,21.1,2.7,42.9,12.5,64c6.7,14.4,13.5,29.2,14.6,43.5 c1.3,16.4-16.6,57.8-29,80.7c-2.4,4.5-3,7.4-1.9,9.3c0.8,1.5,2.7,2.1,5.8,2.1c4.6,0,11.5-1.6,19.6-3.5c11.7-2.7,26.2-6,40-6 s24.7,3.4,32.5,10.4l0,0c6.9,6.4,10.5,15.5,14,24.4c4.1,10.5,8,20.4,16.9,26.1c1.9,1.2,4.9,1.9,8.6,1.9c3.3,0,7.1-0.5,11.3-1.8"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M509.2,671.9c1.4-1.6,3.4-3.2,5.9-4.8 c8.8-5.8,19.8-8.1,30.3-10.4c2.5-0.5,5-1.1,7.5-1.7c32-7.6,62.3-14.7,89.5-57.2c4.7-7.3,8.3-15.7,12.2-24.6 c6.2-14.1,13.2-30.2,22.8-35.5c1.7-0.7,3.3-1.2,4.9-1.7"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M480.2,671.9c3.1-5.6,10.5-11.1,21.3-16 c17.3-7.8,32.9-11.4,48-15c30.4-7.2,56.6-13.4,80.6-50.9c4-6.3,7.5-14.2,11.1-22.6c7.6-17.5,15.5-35.5,30.2-43 c3.9-1.5,7.5-2.7,10.9-3.6"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M295.1,671.9c9-31.5,29.1-50.7,63.7-62.2 c2.7-0.9,5.4-1.7,8.2-2.5c20.2-5.7,40.8-8.7,61.1-8.7c13.8,0,27.7,1.4,41.3,4c20.4,4,32,10.2,34.6,18.5c2,6.3-1.3,14-9.8,22.9 c-8.8,9.2-17.6,18.5-25.6,28"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M423.5,538.7c0.6,0.3,1.2,0.5,1.9,0.5 c2.6,0,5.2-2.6,7.4-4.8c1.3-1.3,2.5-2.5,3.6-3c1.3-0.6,2.5-0.9,3.8-0.9c2.3,0,4.3,1.1,6.6,2.4c0.8,0.5,1.7,0.9,2.6,1.4 c2.2,1.1,4.3,1.6,6.5,1.6s4.3-0.5,6.5-1c2.3-0.6,4.6-1.1,7.4-1.2h0.4c5.6,0,10.7,2.9,15.6,5.7c4.9,2.8,10,5.7,15.5,5.7 c2.6,0,5.2-0.7,7.8-2c2.1-1.1,3.6-2.4,5-3.5c2.3-1.9,4.2-3.5,7.5-3.5c1,0,2,0.1,3.2,0.4c9.4,2.2,13.6,10.9,17.6,19.3 c1.9,4,3.8,8,6.3,11.3c4.9,6.5,11.2,10.1,17.8,10.1h0.3c6.6-0.1,13-3.8,17.9-10.3c6.7-8.8,18.8-37.7,13.8-52.1 c-1.5-4.2-4.3-6.9-8.5-8c-1.5-0.4-2.8-0.6-4.2-0.6c-4.3,0-7.5,1.9-11.2,4.1c-0.8,0.5-1.7,1-2.6,1.5c-7.5,4.3-15,5.2-22.9,6.1 c-3.1,0.4-6.3,0.8-9.5,1.3c-3.6,0.7-6.9,1.9-10,3.2c-4.1,1.6-8,3.1-12.3,3.1c-2.9,0-5.7-0.7-8.7-2.2c-6-3-12.6-5.7-19.9-5.7 c-2.4,0-4.8,0.3-7.2,0.9c-1.1,0.3-2.2,0.5-3.3,0.8c-4.2,1.1-8.2,2.1-12.2,2.1c-3.3,0-6.4-0.7-9.4-2.1l-1.3-0.6 c-5.5-2.6-11.3-5.2-17.1-5.2c-3.2,0-6.2,0.8-9.1,2.4c-3.7,2-8.6,10.7-8.6,16.7C420.5,535.5,421.5,537.6,423.5,538.7L423.5,538.7 L423.5,538.7z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M329,590.8c0.9,0.2,2.6,0.4,4.8,0.4 c4.5,0,21-0.7,46.8-9.2c21.6-7.2,46.8-11.1,71.1-11.1c39.9,0,66.4,6,100.6,38.8c2.4,2.3,5.7,3.5,9.7,3.5c14.8,0,37.3-16.2,51.1-37 c10.8-16.2,26.5-49.2,10.3-88.8c-7-17-15.7-19.4-24.2-19.4c-10.4,0-23.4,4.2-38.5,9.1c-20.3,6.6-43.4,14-68.9,14 c-20.4,0-39.1-4.7-57.1-14.4c-57.2-30.6-58-87.7-53.6-128c1.4-12.5-0.6-22.4-5.4-27.8c-2.9-3.3-6.8-4.9-11.6-4.9 c-10.9,0-24.6,8.9-29.7,26c-4.6,15.4,8.6,32.2,23.9,51.6c19.5,24.8,43.9,55.7,39.1,97.1c-1.4,11.9-4.5,23.1-9.4,33.5 c-7.7,16.5-17.9,30.5-30.4,41.6c-8.2,7.3-16.4,12.5-22.4,16.2c-5.5,3.4-8.8,5.5-8.5,7C326.6,589.9,327.4,590.4,329,590.8 L329,590.8L329,590.8z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M363.9,326.3c-7.9,0-16.5,8-19.3,15.2 c-1.9,4.8-0.4,12.9,3.8,21.7c4,8.4,9.4,14.8,13.3,16c0.6,0.2,1.2,0.3,1.8,0.3c0.7,0,1.3-0.1,2-0.3c5.1-1.8,5-4.9,4.7-11.8 c-0.2-4.2-0.4-10,0.5-18c1.2-10.7-0.5-18.9-2.8-21.4C367.2,327.1,366.2,326.3,363.9,326.3L363.9,326.3L363.9,326.3z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M541.3,539.8c1.6,0.7,2.5,1.2,3.7,1.8 c0.5,0.3,1,0.5,1.6,0.9c6,3.3,11.1,6.6,14.7,9.6c2,1.7,3.7,2.5,5,2.5c2,0,3.2-1.7,4.1-3.3c3.5-6.1,4.8-10.8,6.3-19 c0.2-1,0.2-2.4-0.6-3.4c-1.1-1.3-3.2-1.5-6.2-0.6c-9.3,2.6-15.6,3.8-23.5,5.3c-1.4,0.3-2.8,0.5-4.3,0.8c-1.5,0.3-2.7,1.4-2.8,2.6 C539.1,537.5,539.2,538.9,541.3,539.8L541.3,539.8L541.3,539.8z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M406.7,555.3c1.4,0,3-0.1,5.1-0.3 c0.9-0.1,22.2-2.5,45-2.5c8.9,0,16.8,0.4,23.6,1.1c33.7,3.6,62.9,19.8,76.8,34.2c2.6,2.7,5.6,4,9.1,4c7.8,0,17.4-6.8,26.4-18.7 c15.7-20.9,20.5-60.8,12.7-76.5c-2.1-4.2-5-6.4-8.4-6.4c-0.3,0-0.6,0-0.9,0c-6.2,0.6-14.7,3.1-25.5,6.4c-20.3,6-48,14.2-78.2,14.6 c-25.7,0.4-55.1-9.8-65.1-14.7c-3.3-1.6-5.4-2.3-6.9-2.3c-3,0-3.1,2.8-3.3,7.6c-0.2,3.8-0.4,8.9-2.2,14.8 c-3.3,10.7-7.1,18.8-9.8,24.7c-3,6.5-4.9,10.5-3.6,12.6C402.2,554.8,403.8,555.3,406.7,555.3L406.7,555.3L406.7,555.3z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M373.9,572.2c1.4,0,3.1-0.3,4.9-1 c20.5-7.2,44.3-10.6,72.8-10.6c35.4,0,70.5,3.3,110.5,42.7c1.1,1.1,2.7,1.7,4.5,1.7l0,0c11.2,0,31.8-21.5,37.9-34.4 c0.6-1.2,1.2-2.4,1.8-3.7c7.8-16,20.8-42.9,7.5-75.3c-5.3-13-10.3-13-14.7-13c-8.8,0-21.1,4-35.4,8.6c-21,6.8-44.9,14.5-72,14.5 c-23.2,0-42-4.3-61-13.9c-2.9-1.5-5.8-3.1-8.2-4.5c-5-2.9-8.9-5.1-11.5-5.1c-2,0-3,1.4-3,4.2c0,0.5,0.1,1.2,0.2,2.2 c2.7,28-8.1,54.1-32,77.6c-4.2,4.1-6,7.1-5.3,8.8C371.2,571.7,372.3,572.1,373.9,572.2L373.9,572.2L373.9,572.2z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M294,9c-2.3,7.9-3.8,16.6-4.8,26.2 c-1.6,16.6-3.1,38.4,0.3,59.3c2.5,15.3,7.4,22.1,16,22.1l0,0c5.5,0,11.9-2.7,19.4-5.8c5.2-2.2,10.5-4.4,16.2-6 c0.5-0.2,1.1-0.3,1.6-0.5c16.2-4.4,34.5-5,53.9-1.8c5.2,0.8,10.5,2.3,15.6,3.7c7.5,2.1,15.1,4.2,23,4.6c4.2,0.2,7.3-0.2,9.4-1.3 c6.2-3.1,3.2-10.9,0-19.2c-1.9-4.9-3.9-9.9-4.2-14.4c-1.7-24,11.7-45.9,29.3-66.9"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M281.9,421.2c-1-0.9-1.9-1.3-2.7-1.3 c-2,0-3.5,2.6-5.7,6.6c-2.3,4.1-5.3,9.7-10.7,15.4l-1.6,1.7c-6.1,6.5-8.9,9.5-8.2,11.1c0.3,0.7,1.3,1,3.1,1c1.5,0,3.8-0.2,7.6-0.8 c1.5-0.2,3.1-0.3,4.7-0.3c12.7,0,23.3,7.2,31,12.4c4.5,3.1,8.1,5.5,10.7,5.5c1.1,0,2-0.4,2.6-1.3 C320.9,460.8,292.5,430.9,281.9,421.2L281.9,421.2z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M282.1,433.5c-5.1,0-11.7,8.1-11.8,12 c0,1.6,1,2.5,2.7,2.5c7.6,0.1,15.6,5,21.4,8.5c3.5,2.1,6,3.6,7.4,3.6l0,0c0.5,0,0.9-0.1,1.1-0.4c0.3-0.4,0.5-0.9,0.4-1.7 c-0.5-7.1-13.9-21.3-19.4-24C283.5,433.7,282.8,433.5,282.1,433.5L282.1,433.5L282.1,433.5z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M264,299.9L264,299.9c1.3,0,2.3-1.8,3.2-5.8 c3.7-15,15.8-34.9,34.7-45.5c19.1-10.7,31.8-13.5,38.7-15c3.4-0.7,5.1-1.1,5.5-2c0.2-0.3,0.1-0.8-0.1-1.2 c-1.3-2.4-25-15.7-59-18.5l-1.4-0.1c-3.1-0.2-5.7-0.5-8.2-0.5c-8.9,0-16,2.5-32.8,16.7c-2.6,2.2-5.3,4.3-7.9,6.3 c-8.1,6.2-15.1,11.6-14.6,17.7c0.4,4.7,5,9.3,14.5,14.6c10.6,5.9,17,17.5,21.3,25.2C260.5,296.7,262.3,299.9,264,299.9L264,299.9 L264,299.9z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M266.8,325.2c1.3,0,2.2-1.5,2.8-4.8 c3.8-20.3,12.1-49,39.6-64.3c8.5-4.7,18.1-8.5,29.4-11.5c2.3-0.6,4.7-2.2,7-3.8c3-2.1,6.2-4.2,9.1-4.2c0.3,0,0.7,0,1,0.1 c1.6,0.3,2.8,1.2,4,2.1c1.3,1,2.6,2,4.3,2c0.6,0,1.3-0.1,2-0.4c1.2-0.5,1.9-1.4,2.5-2.4c0.9-1.3,1.7-2.6,4.2-2.3 c2.5,0.2,3.8,2.1,5.2,4.1c1,1.4,2,2.9,3.5,3.7c1.1,0.6,3,1.2,4.6,1.2c1.2,0,2-0.3,2.5-0.9c0.4-0.6,0.5-1.4,0.2-2.5 c-1-3.5-8.4-6.7-13.3-8.9c-1.5-0.6-2.7-1.2-3.5-1.6c-7.3-3.9-14.6-7.2-20.8-9.9c-26-11.4-49.7-18.4-72.4-21.3 c-2.6-0.3-4.8-0.5-6.8-0.5c-11.2,0-19.9,4.9-37.1,21.1c-4.2,4-8.6,7.4-12.8,10.6c-9.4,7.4-16.9,13.2-16.7,20.5 c0.1,5.8,5.1,11.9,15.7,19.4c17,12,28.3,30.2,35.8,42.2C261.6,320.3,264.6,325.2,266.8,325.2L266.8,325.2L266.8,325.2z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M320.9,510c10-1.1,19.5-8.1,24.1-17.6 c4.2-8.7,6.5-23.5-6.5-44.5c-5.6-9-11.7-18.1-17.6-26.9c-16.9-25.1-32.8-48.9-39.4-72.7c-7.4-27-1.5-49.2,18.8-70 c17.5-18,40.7-28.3,63.6-28.3c17.2,0,32.5,6.1,43.2,17.1c11.7,12.1,17.6,29.9,16.9,51.3c-0.1,5-0.4,10.1-0.6,15.6 c-0.9,19.4-1.9,41.3,1.2,59.5c3.6,21,12.1,34,25.9,39.6c7.4,3,14.5,4.6,20.9,4.6c11.7,0,21.7-5.2,28.9-14.9 c12.2-16.6,15.1-44.9,7.3-70.6c-16.8-55.1-57.2-87.9-117-123.1c-45.4-26.7-83.8-35-118.4-39.9c-2.6-0.4-5-0.6-7.3-0.6 c-14.5,0-21.4,7.4-30.1,16.8c-3.4,3.6-6.8,7.3-11.1,11.1c-5,4.4-9.3,7-13.1,9.4c-6.1,3.8-11.4,7-17.2,15.8 c-11.5,17.3,3.9,27.9,20.2,39.2c8.6,5.9,17.6,12.1,22.3,19.4c30,45.7,17.9,94.5-3.4,124.9c-6.1,8.7-15,16.9-24.5,25.6 c-11.5,10.6-23.5,21.6-30.3,33.9c-2.9,5.3-3.8,8.9-2.6,10.9c0.9,1.5,2.9,2.3,6,2.3l0,0c5.6,0,14.6-2.3,24.9-5.1 c14.5-3.8,32.6-8.5,48.6-8.5c8.5,0,15.7,1.4,21.6,4c5.8,2.7,10.8,6.5,15.5,10.2c7.8,6.1,15.2,11.9,25.7,11.9 C318.6,510.2,319.8,510.2,320.9,510L320.9,510L320.9,510z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M330.5,491.8c3.8-2.8,6.1-6.8,6.7-11.7 c1.7-14.5-11-35.6-22.5-49.3c-14.3-17.1-28.5-35.3-35.5-53c-0.3-0.9-0.9-3.3-1.5-6.4c-2.2-10.5-6-28-11.4-28c-0.5,0-1,0.1-1.5,0.5 c-2.3,1.3-2.9,4-2,8.1c0.7,3.3,2.4,7.2,3.8,10.6c1.5,3.6,2.9,6.7,2.9,8.5c-0.1,4.5-2.2,6.5-4.2,8.5c-1.6,1.5-3.2,3.1-3.8,5.8 c-0.8,3.5,0.7,6.1,2.1,8.7c1.3,2.4,2.6,4.8,2.2,7.9c-0.8,5.8-18.9,26.5-34.9,44.8c-13.2,15.2-24.7,28.2-24.5,30.8 c0,0.2,0.1,0.3,0.2,0.4c1.1,0.9,3.1,1.4,6.1,1.4l0,0c4.7,0,11.3-1.1,19-2.3c9.2-1.5,19.7-3.2,28.8-3.2c7.1,0,12.4,1,16.3,3.1 c2.1,1.1,4.4,2.5,6.9,3.9c10.9,6.4,24.5,14.3,36.1,14.3C323.8,495.2,327.5,494,330.5,491.8L330.5,491.8L330.5,491.8z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M325.5,473.3c1.3-12.1-11.6-31.2-18.1-38.5 c-11.2-12.6-18.9-23.4-24.1-30.5c-5.1-7.1-7.9-11-9.7-11c-1.2,0-1.9,1.6-2.4,5.6c-3.2,30-22.5,46.2-34,55.9 c-5.1,4.3-8.4,7.1-8.5,9.4c0,0.7,0.2,1.3,0.7,1.8c1.1,1.1,2.7,1.6,5.2,1.6c2.8,0,6.4-0.7,10.6-1.4c5.4-1,11.5-2.1,17.8-2.1 c8.5,0,15.4,2.1,21,6.5c7.9,6.2,19.7,12.8,28.9,12.8l0,0c3.3,0,6.1-0.8,8.4-2.5C323.8,479.2,325.2,476.6,325.5,473.3L325.5,473.3 L325.5,473.3z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M450.8,352.1c-1.5,23,5.1,38.9,11.7,46 c3,3.3,6.4,5.1,9.4,5.1l0,0c4.3,0,7.5-3.4,9-9.5c10-39.4-24.1-81-28.3-84.5c-1.2-1-2-1.1-2.6-0.5c-1,1.1-0.7,4.6-0.1,11 C450.7,327.2,451.7,338.6,450.8,352.1L450.8,352.1L450.8,352.1z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,138.5c-15.9-10.4-35.2-19.5-60.1-19.5 c-10.2,0-20.7,1.6-31.4,4.6c-25.3,7.3-48.8,21.3-67.8,40.5c-4.4,4.4-8.2,9.1-11.5,13.2c-7.2,8.9-12.9,16-21.3,16.8 c-0.5,0-1,0.1-1.5,0.1c-5.8,0-12.7-3-21.2-9.2c-8.7-6.4-12.6-11-12.4-14.7c0.2-4,5.1-6.9,11.3-10.7c2.7-1.7,5.9-3.6,9-5.8 c11.1-7.9,15.6-20,19.7-30.7c6.3-16.6,15.8-19.8,31.2-19.8c2.5,0,5.1,0.1,7.8,0.2c1,0,2.1,0.1,3.2,0.1c1.3,0,2.6,0,3.9,0 c18.1,0,34.8-2.9,50.9-5.6c13.4-2.3,26.1-4.5,37.5-4.5c6.6,0,12.4,0.7,17.7,2.2c11.4,3.3,23,8.6,35,14.8"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M439.5,350.8c-1.7,22.4,2.7,40.4,12.4,50.9 c5.1,5.5,11.3,8.5,17.5,8.5l0,0c8.9,0,16-6.2,18.1-15.8c6.2-28-3.4-53.3-12.5-69.5c-10.7-19.1-24.4-32.5-31-37 c-2.4-1.6-4.1-2.6-5.1-2.6c-0.4,0-0.7,0.1-0.9,0.4c-0.9,1-0.5,3.7,0.2,9.1C439.4,304.5,441.6,322.4,439.5,350.8L439.5,350.8 L439.5,350.8z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M431,352.2c-1.7,27.8,3.6,49.8,14.9,61.9 c5.9,6.2,13,9.5,20.5,9.5l0,0c14.5,0,26.8-11.8,30.6-29.4c5.6-25.9,0.8-51.4-14.3-75.8c-15.3-24.7-36.4-40.7-47.6-48 c-5.7-3.7-8.2-5.3-9.1-4.1c-0.8,0.9-0.3,3.6,0.7,9C429,286.8,433.5,310.9,431,352.2L431,352.2L431,352.2z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M262.3,259.8c4.1,0,7.1-4,10.3-10.1 c5.6-10.6,17.2-15.7,23.4-18.4c3.1-1.4,4.3-1.9,4.2-2.8c-0.1-0.7-0.9-1.1-5.2-1.3c-13-0.9-42.1,11.6-45.5,20.9 c-0.8,2.2-0.2,4.1,1.7,5.7C256.1,258,259.5,259.8,262.3,259.8L262.3,259.8L262.3,259.8L262.3,259.8z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M275.1,259.3c10-18.3,28.2-24.7,43-28.2 c1.4-0.3,1.5-1.1,1.5-1.5c-0.4-3-14.2-8.7-34.9-8.7c-2.3,0-4.7,0.1-7.1,0.2c-9.4,0.6-31.7,13.8-39.7,23.6c-2.1,2.6-3,4.6-2.8,6.3 c0.6,4.1,4.1,5.9,8.3,7.9c4.4,2.2,9.4,4.7,11.9,10.5c1.4,3.4,3.3,5.1,5.5,5.1C264.7,274.7,269.5,269.5,275.1,259.3L275.1,259.3 L275.1,259.3z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M468,385.6L468,385.6c0.8,0,1.5-0.5,2-1.6 c2.9-5.4,2.8-24-4.8-35.3c-1-1.5-2.4-1.3-3.5,0.6c-2.8,4.9-4,20.6,3.7,34.2C466.2,384.9,467.1,385.6,468,385.6L468,385.6 L468,385.6z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,226.3c-28.4-16.9-53.4-27-67.7-27 c-1.1,0-2.1,0.1-3,0.2c-18.5,2.5-52.5,39.1-60,60.2c-5.4,15.3,3,33.5,11.9,52.8c7.2,15.6,14.7,31.7,16,48.6 c0.7,8.6-6.3,39.3-16.3,66.2c-0.5,1.5-0.5,2.6,0.1,3.5c1,1.5,3.6,2.2,8.5,2.2c3.1,0,6.8-0.2,11-0.5c5.3-0.4,11.3-0.8,17.5-0.8 c19.6,0,32.8,4.2,41.6,13.2c8.2,6.3,12.7,16.7,16.7,25.8c4.8,11.1,9,20.6,18.3,20.6c1,0,2-0.1,3.1-0.3c0.7-0.1,1.5-0.3,2.2-0.6"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M675.6,671.9c-12.7-5.3-26.8-8-42.7-8 c-0.8,0-2.3,0.1-4,0.2c-3,0.2-7.2,0.5-11.3,0.5c-10.2,0-15.5-1.7-15.9-5c-0.5-5,11.3-12.4,19.1-17.3c3.1-2,5.8-3.6,7-4.7 c9.7-8.6,18.7-19.2,26.7-31.7c5.3-8.3,9.2-17.2,13.3-26.6c3.1-7.1,8-14.5,14.5-19.6"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M352,9c-5.2,6-8.5,13.6-10.5,23.5l-0.2,1.2 c-1.5,7.4-1.1,12,1,14.7c1.5,1.8,3.9,2.7,7.2,2.7c2,0,4.2-0.3,7-0.9c2.7-0.6,5.3-1.2,7.6-1.5c3.9-0.5,7.7-0.8,11.1-0.9 c3.9-0.2,7.3-1.3,10.6-3.2c13.6-8,19.4-24.4,21.2-31.1c0.4-1.6,0.9-3.1,1.3-4.5"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,126.2c-16-9.6-35.6-17.6-60.1-17.6 c-11.1,0-22.6,1.7-34.2,5c-2.5,0.7-5.9,1.5-9.8,2.5c-14.2,3.4-33.6,8-39.4,15.1c-2,2.4-2.3,5.4-2.7,8.2c-0.3,3.2-0.7,6.2-3.2,8.5 c-2.1,1.9-4.5,2.2-6.8,2.2c-0.6,0-1.3,0-1.9,0c-0.6,0-1.3,0-1.9,0c-1.7,0-3.7,0.1-5.5,1.2c-1.7,1.1-4.5,5.1-8.1,10.2 c-5.6,8-13.2,19-20,22.5c-4.1,2.1-7.4,1.3-10-2.6c-3.9-5.7,4.7-12.1,12.3-17.8c3.9-2.9,7.2-5.5,8.6-7.6c3.7-5.9,4.3-12.8,4.9-19.5 c1.1-12.2,2-22.7,21.9-22.7c2.4,0,4.9,0.1,7.5,0.2l3.2,0.1c1.4,0,2.8,0,4.2,0c19,0,36.1-2.9,52.6-5.8c13-2.2,25.2-4.3,35.8-4.3 c5.6,0,10.5,0.6,14.8,1.9c12,3.4,24.6,9.6,37.9,16.6"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M368,9c-4.3,2.5-7.7,5.4-10.3,9 c-5.2,7.4-7.2,13.9-5.4,17.5c1.1,2.2,3.6,3.3,7.2,3.3c1,0,2-0.1,3.1-0.2c4.3-0.6,8.3-0.8,11.9-1c1.1-0.1,2-0.2,3-0.5 c1.1-0.3,2.1-0.7,3-1.3c7.9-4.6,17.3-18.4,17.6-26.8"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,476.7c-0.2,0-0.3,0.1-0.5,0.1 c-5,0-8.3-8.6-12.2-18.6c-3.9-10.1-8.3-21.6-15.7-28.1l-0.2-0.1c-13.4-12.2-28.2-12.8-41.2-13.2c-2.8-0.1-5.4-0.2-7.9-0.4 c-0.9-0.1-1.8-0.1-2.7-0.2c-7.8-0.6-13.5-1-16.1-4.8c-2.5-3.6-2.1-10.2,1.1-21.6c3-10.6,4.1-20.2,3.4-29.5 c-1.5-18.9-9.3-35.9-17-52.4c-8-17.2-15.5-33.4-11.5-44.5c6.5-18.6,38.1-51.3,51.2-53.1c0.3,0,0.8-0.1,1.5-0.1 c10.9,0,35.6,9.1,67.7,28.9"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M318.1,544.8c21.8-2.5,47.8-28.6,54.8-48.5 c12.1-34.6-9.4-62.4-30.2-89.3c-20.1-26-39-50.5-29.8-80.4c8-26,34-40.1,54.3-40.1c11,0,20.4,3.9,26.7,11 c7.4,8.4,10.4,21.2,8.5,37c-6.5,54.2-8.5,113.6,51.7,131.1c6.9,2,13.7,3,20.3,3c25.4,0,41.5-14.6,50.6-26.8 c17.5-23.5,23.5-58.6,15.1-87.3c-7.2-24.4-21.2-48.1-43-72.3c-32.4-36.1-76.6-67.1-135-94.9l-2.5-1.2 c-24.6-11.7-50-23.8-76.7-30.4c-1.6-0.4-3.3-0.9-5.2-1.4c-8.3-2.2-18.5-4.9-27.4-4.9c-3.5,0-6.6,0.5-9.1,1.4c-3.8,1.4-5.9,4-8,6.5 c-1.6,1.9-3.1,3.8-5.4,5.2c-1.5,0.9-4.4,2-7.4,3.2c-4.9,1.9-10.1,3.9-11.7,5.8c-3.1,3.7-2.3,5.9-1.4,8c0.6,1.5,1.2,3,0.4,5.2 l-0.6,1.7c-3,8.8-7.9,14.1-14.7,16.1c-2,0.6-4.4,1.3-7.4,2.2c-8.4,2.7-11.9,6.9-15.2,10.9c-2.6,3.1-5,6-9.6,8.4h-0.4 c-5.3,0-45.3,2.6-48.4,12.5c-0.8,2.7,1,5.6,5.3,8.6c5.5,3.8,9.6,4.3,12.3,4.3c1.3,0,2.5-0.1,3.6-0.2c1-0.1,1.9-0.2,2.7-0.2 c3.2,0,6.5,0.9,10.9,12c1.1,2.7,1.9,5,2.6,6.8c3.5,9.1,3.6,9.2,12.4,13c2.2,0.9,4.9,2.1,8.3,3.7c11.3,5.2,13.6,11.2,16.6,18.7 c1.6,4,3.3,8.5,6.6,13.6c3.6,5.4,7.7,9.8,11.8,14c7.2,7.5,13.9,14.6,16.1,26.4c3.7,20.5-7.3,40-19.9,58.1 c-6.1,8.8-18.6,19-31.8,29.8c-22.4,18.4-47.9,39.2-47.9,58.4c-0.1,11,2.8,19.4,8.4,25.1c6.1,6.2,15.4,9.2,28.3,9.2 c18.8,0,41.9-6.4,62.3-12c16.1-4.4,29.9-8.2,38.8-8.2c2,0,3.7,0.2,5,0.6c8.5,2.5,15.3,8,21.9,13.3c8.7,7,16.9,13.6,28.7,13.6 C315.6,545.1,316.9,545,318.1,544.8L318.1,544.8L318.1,544.8z"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M339.4,9c-3.7,6.1-6.3,13.2-8,21.5l-0.2,1.1 c-1.5,7.4-4.2,21.1,1.4,27.2c2.3,2.5,5.9,3.8,10.8,3.8s10.5-1.2,15.4-2.3c2.5-0.6,4.8-1.1,6.7-1.3c3.5-0.5,7-0.7,10.3-0.9 c6.9-0.4,13.5-0.8,18.7-3.8c4.2-2.4,7.2-6.3,9.3-12.1c3.8-10.3,8.4-21.8,15-33.3"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M304.9,9c-2.5,8.1-4.3,17.1-5.3,27.2 c-0.2,1.8-0.4,3.7-0.6,5.5c-1.5,14-3.2,29.9,2.9,43.2c3,6.6,9.1,9.8,18.6,9.8c4.9,0,9.9-0.8,14.3-1.5l0.2,0 c2.1-0.3,4.2-0.7,6.3-1.1c12.5-2.1,24.2-4.1,36.6-4.1c3.9,0,7.6,0.2,11.3,0.6c4.9,0.5,10.1,2.2,15.1,3.7 c5.6,1.8,10.9,3.4,15.3,3.4c3.3,0,5.7-0.9,7.5-2.9c2.6-2.9,3.6-8.2,3.1-16.2c-1.4-19.7,5.7-39.9,21.8-61.8c1.3-1.7,3-3.7,5-5.8"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M682.3,187.3c-1.7-1.3-3.4-2.6-5.1-3.9 c-16.8-12.8-32.7-24.9-55-24.9c-6.6,0-13.3,1-20.5,3.1c-21.7,6.2-41.3,18.9-55.4,35.8c-8,9.6-9,17.9-10.1,26.8 c-0.8,6.2-1.6,12.6-4.8,19.6c-1.3,2.8-3.6,4.6-5.8,6.5c-3.2,2.6-6.5,5.3-6.7,10.7c-0.2,4.5,3.1,8.5,6.2,12.5 c2.8,3.5,5.8,7.2,6.1,11.1c0.8,8.5-0.1,11.4-1,12.3c-0.3,0.3-0.6,0.4-0.9,0.4c-4,0-12.7-15-16.3-21.4l-0.8-1.4 c-5.4-9.3-11.3-12-17.5-14.8c-4.5-2-9.1-4.2-14-8.9c-4-3.9-7.1-8.1-10.2-12.3c-3.5-4.7-7-9.6-11.9-13.9c-2-1.8-3.9-3.4-5.6-5 c-13.5-12.1-19-17.1-39.4-21.9c-9.2-2.2-12-6.8-15-11.8c-2.5-4.1-5-8.2-11-11.4c-2.5-1.3-5.3-2-8.5-2s-6.6,0.7-9.8,1.3 c-3.1,0.6-6.4,1.3-9.6,1.3c-4.4,0-8-1.3-11.2-4c-2.5-2.1-3.7-6-4.9-9.7c-0.9-3-1.9-6-3.5-8.3c-1.5-2-3.9-3.2-6.3-4.5 c-2.8-1.4-5.5-2.8-6.5-5.3c-2.3-5.6-2.2-10.4,0.2-14.1c5.9-8.9,23.9-10.8,37.9-10.8c7.7,0,13.9,0.6,16.2,0.8 c4.7,0.5,10.5,1.7,16.7,2.9c10.5,2.1,22.4,4.5,33.1,4.5c9.2,0,16.2-1.8,21.4-5.4c12.8-9.1,9.1-22.9,5.6-36.2 c-3.8-14.2-7.7-28.9,8.1-38.5c1.9-1.2,4.4-1.7,7.5-1.7c9.5,0,22.8,5.2,35.6,10.2c11.2,4.4,21.8,8.5,28.7,8.7c1,0,2,0,3,0l0,0 c14.8,0,29.8-2.6,44.3-5c14.5-2.5,29.4-5,44.2-5c10.3,0,19.7,1.2,28.5,3.8c8.2,2.3,16.2,5.5,24.2,9"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M316.4,9c-2.2,5.4-3.9,11.2-5.1,17.6 c-2,9.9-3.8,24.7-1.7,38.6c1.9,12.7,7.1,17.9,17.8,17.9c3.1,0,6.8-0.4,12.2-1.3c13.7-2.3,27.3-3.4,42.7-3.4 c3.9,0,7.9,0.1,12.2,0.2c1.5,0,2.8,0.1,4,0.1c7.2,0,11.4-0.9,14.4-3c3.7-2.6,5.9-7,7.3-15C423.6,42.4,430.9,26,443.2,9"
          />
          <path
            style={{
              fill: "none",
              stroke: "#DEDEDE",
              strokeWidth: 0.5,
              strokeMiterlimit: 10,
            }}
            d="M327.7,9c-2.8,5.8-4.9,12.3-6.3,19.5 c-2.1,10.5-5.9,30,1.3,38.8c2.5,3,6.1,4.6,10.8,4.6l0,0c1.4,0,2.9-0.1,4.4-0.4c11.7-2,24.1-3.1,37.1-3.4c3-0.1,5.9,0,8.7,0.1 c1.9,0,3.6,0.1,5.3,0.1c5.3,0,10.7-0.4,14.9-3.4c3.5-2.5,6-6.5,7.6-12.5c4.4-16.1,10.7-30.4,19.2-43.4"
          />
        </g>
      </g>
      <g id="Background">
        <g id="Axis">
          <g>
            <g>
              <line
                fill="none"
                stroke="#999999"
                strokeWidth={2}
                strokeMiterlimit={10}
                x1={65.5}
                y1={71.2}
                x2={65.5}
                y2={735}
              />
              <g>
                <polygon
                  fill="#999999"
                  points="60.5,72.6 65.5,64 70.5,72.6  "
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <line
                fill="none"
                stroke="#999999"
                strokeWidth={2}
                strokeMiterlimit={10}
                x1={728.8}
                y1={736}
                x2={65}
                y2={736}
              />
              <g>
                <polygon
                  fill="#999999"
                  points="727.4,731 736,736 727.4,741  "
                />
              </g>
            </g>
          </g>
        </g>
        <g id="Grid">
          <line
            fill="none"
            stroke="#999999"
            strokeMiterlimit={10}
            x1={394.5}
            y1={72}
            x2={394.5}
            y2={736}
          />
          <line
            fill="none"
            stroke="#999999"
            strokeWidth={0.25}
            strokeMiterlimit={10}
            x1={230}
            y1={72}
            x2={230}
            y2={736}
          />
          <line
            fill="none"
            stroke="#999999"
            strokeWidth={0.25}
            strokeMiterlimit={10}
            x1={559.8}
            y1={72}
            x2={559.8}
            y2={736}
          />
          <line
            fill="none"
            stroke="#999999"
            strokeWidth={0.5}
            strokeMiterlimit={10}
            x1={725}
            y1={72}
            x2={725}
            y2={736}
          />
          <line
            fill="none"
            stroke="#999999"
            strokeMiterlimit={10}
            x1={725}
            y1={404}
            x2={65}
            y2={404}
          />
          <line
            fill="none"
            stroke="#999999"
            strokeWidth={0.25}
            strokeMiterlimit={10}
            x1={725}
            y1={238}
            x2={65}
            y2={238}
          />
          <line
            fill="none"
            stroke="#999999"
            strokeWidth={0.25}
            strokeMiterlimit={10}
            x1={725}
            y1={570}
            x2={65}
            y2={570}
          />
          <line
            fill="none"
            stroke="#999999"
            strokeWidth={0.5}
            strokeMiterlimit={10}
            x1={725}
            y1={72}
            x2={65}
            y2={72}
          />
        </g>
        <g id="Labels">
          <g id="Proficiency">
            <text
              transform="matrix(0 -1 1 0 53.225 442.5382)"
              fill="#999999"
              className="font-sans"
              fontSize="12px"
            >
              {"Proficiency (PF)"}
            </text>
            <text
              transform="matrix(0 -1 1 0 52.8546 81.378)"
              fill="#999999"
              className="font-sans"
              fontSize="12px"
            >
              {"100"}
            </text>
            <text
              transform="matrix(0 -1 1 0 53.3564 739.1241)"
              fill="#999999"
              className="font-sans"
              fontSize="12px"
            >
              {"0"}
            </text>
          </g>
          <g id="Priority">
            <text
              transform="matrix(1 0 0 1 367.5788 756.9596)"
              fill="#999999"
              className="font-sans"
              fontSize="12px"
            >
              {"Priority (PR)"}
            </text>
            <text
              transform="matrix(1 0 0 1 719.5465 756.5891)"
              fill="#999999"
              className="font-sans"
              fontSize="12px"
            >
              {"100"}
            </text>
            <text
              transform="matrix(1 0 0 1 61.8 757.0911)"
              fill="#999999"
              className="font-sans"
              fontSize="12px"
            >
              {"0"}
            </text>
          </g>
        </g>
      </g>
      <g id="Foreground" transform="translate(68,74)">
        <g id="Job_Title" transform={`translate(${jobX},${jobY})`}>
          <circle fill="#FFC23D" cx={0} cy={0} r={16} className="opacity-70"/>
        </g>
        <g id="Company_Size" transform={`translate(${revX},${revY})`}>
          <circle fill="#FF5149" cx={0} cy={0} r={12} className="opacity-50" />
        </g>
        <g id="Industry" transform={`translate(${indX},${indY})`}>
          <circle fill="#00A4B3" cx={0} cy={0} r={20} className="opacity-50"/>
        </g>
        <g id="You_Are_Here" transform={`translate(${priX},${priY})`}>
          <circle fill="#4F2D7F" cx={0} cy={0} r={16} />
        </g>
      </g>
    </svg>
  );
};

export default ResultsChart;
