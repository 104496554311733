import { useState, useEffect } from "react";

function appendScript(baseUrl, setScriptLoaded) {
  if (window.MktoForms2) return setScriptLoaded(true);

  const script = document.createElement("script");
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null);
  document.body.appendChild(script);
}

function useMarketo({ data, baseUrl, munchkinId, formId, callback }) {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  //console.log("formData inside", data);

  useEffect(() => {
    if (scriptLoaded) {
      //console.log("formData inside callback", callback);
      // console.log("formData inside effect", data);
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, callback);
      //console.log("yeah");
      window.MktoForms2.whenReady(function (form) {
        //console.log("form", form);
        // form.addHiddenFields({
        //   Email: "user@example.net",
        // });
        // form.vals(data);
        var vals = form.vals();
        //console.log("whenReady form vals", JSON.stringify(vals));
        // form.submittable();
        form.validate();
        form.submit();
      });
      return;
    }
    appendScript(baseUrl, setScriptLoaded);
  }, [data, scriptLoaded, baseUrl, munchkinId, formId, callback]);
}

export default useMarketo;
