import React, { useState, useEffect, useRef } from 'react';
import { ReactSVG } from "react-svg";
import ResultsChart from "./resultsChart";
import { ScoreProgress } from "./scoreProgressChart";
import { CaseStudies } from "./caseStudies";
import { Experts } from "./experts";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import uuid from "react-uuid";
import parse from "html-react-parser";
import "react-tabs/style/react-tabs.css"; 

// import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import PDFMerger from 'pdf-merger-js/browser';
import { saveAs } from "file-saver";
import { pdf, Page, Image, Text, View, Document, StyleSheet, Font, Link } from '@react-pdf/renderer';
import sustainabilityIcon from "../../assets/sustainability-icon.svg";
import purpleCircle from "../../assets/purpleCircle.svg";
import redCircle from "../../assets/redCircle.svg";
import yellowCircle from "../../assets/yellowCircle.svg";
import tealCircle from "../../assets/tealCircle.svg";
import graphLegend from '../../assets/graph-legend.png'; 
import coverShape from '../../assets/Cover_shape.png'; 
import grantThornton from "../../assets/GrantThornton.svg";
import download from "../../assets/download.svg";

const pdfForeword = "https://res.cloudinary.com/da8yug5n2/image/upload/v1680109785/Report%20Assets/GT_ResultsReport_P2_vpqiz2.pdf";
const pdfSupply = "https://res.cloudinary.com/yumyoshojin/image/upload/v1674837745/Grant%20Thornton/Jan/supply_feb17.pdf";
const pdfESGStrategy = "https://res.cloudinary.com/yumyoshojin/image/upload/v1674837746/Grant%20Thornton/Jan/esg_feb17.pdf";
const pdfClimate = "https://res.cloudinary.com/yumyoshojin/image/upload/v1674837745/Grant%20Thornton/Jan/climate_feb17.pdf";
const pdfPeople = "https://res.cloudinary.com/yumyoshojin/image/upload/v1674837745/Grant%20Thornton/Jan/people_feb17.pdf";
const pdfTax = "https://res.cloudinary.com/yumyoshojin/image/upload/v1674837745/Grant%20Thornton/Jan/esg_and_tax_feb17.pdf";
const pdfSustainable1 = "https://res.cloudinary.com/da8yug5n2/image/upload/v1680109786/Report%20Assets/GT_ResultsReport_P10_xeneyr.pdf";
const pdfSustainable2 = "https://res.cloudinary.com/da8yug5n2/image/upload/v1680109785/Report%20Assets/GT_ResultsReport_P11_gv1lsk.pdf";
const pdfSustainable3 = "https://res.cloudinary.com/da8yug5n2/image/upload/v1680109785/Report%20Assets/GT_ResultsReport_P12_huwogd.pdf";
const pdfReport = "https://res.cloudinary.com/da8yug5n2/image/upload/v1680109789/Report%20Assets/GT_ResultsReport_P13-23.pdf";

type Props = {
  totalPairedScore: any;
  pairedResults: any;
  totalPriority: any;
  totalProficency: any;
  totalResults: any;
  resultsData: any;
  measurementResults: any;
  sustainableResult: any;
  sustainableFinance: any;
  sections: any;
  pageLabels: any;
  pageMaxScores: any;
  panelTitles: any;
  isCompleted: boolean;
  compareByIndustry: any;
  compareByJobFunc: any;
  compareByRevenue: any;
};



Font.register({
  family: 'GTWalsheimPro',
  src: 'https://res.cloudinary.com/yumyoshojin/raw/upload/v1669719268/Grant%20Thornton/GT-Walsheim-Pro-Regular.ttf'
});

Font.register({
  family: 'GTWalsheimProBold',
  src: 'https://res.cloudinary.com/yumyoshojin/raw/upload/v1669719268/Grant%20Thornton/GT-Walsheim-Pro-Bold.ttf'
});
Font.register({
  family: 'GTWalsheimProMedium',
  src: 'https://res.cloudinary.com/yumyoshojin/raw/upload/v1669719268/Grant%20Thornton/GT-Walsheim-Pro-Medium.ttf'
});
Font.register({
  family: 'GTWalsheimProLight',
  src: 'https://res.cloudinary.com/yumyoshojin/raw/upload/v1676237256/Grant%20Thornton/gt-walsheim-light.ttf'
});
// Create pdf styles
const pdfStyles = StyleSheet.create({

  page: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    padding: 70,
  },
  cover: {
    flexDirection: 'row',
    backgroundColor: '#FFFFFF',
    paddingLeft: 50,
  },
  border: {
    height: 270,
    width: 330,
    padding: 20,
    backgroundColor: 'white',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 100,
    borderBottomLeftRadius: 10
  },
  border1: {
    marginLeft: 12,
    border: 1,
    height: 110,
    width: 140,
    padding: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  },
  coverTitle1: {
    fontFamily: 'GTWalsheimPro',
    fontSize: 40,
    lineHeight: 1,
    color: '#4F2D7F',
    marginTop: 0,
  },
  coverTitle2: {
    fontFamily: 'GTWalsheimPro',
    fontSize: 40,
    lineHeight: 1,
    color: '#00A4B3',
    marginTop: 8,
  },
  coverSubtitle1: {
    fontFamily: 'GTWalsheimPro',
    fontSize: 20,
    lineHeight: 1,
    marginTop: 40,
  },
  coverSubtitle2: {
    fontFamily: 'GTWalsheimPro',
    fontSize: 20,
    lineHeight: 1,
  },
  titleBold: {
    fontFamily: 'GTWalsheimProBold',
    marginLeft: 12,
    marginTop: 0,
    marginBottom: 10,
    color: '#4F2D7F',
    fontSize: 36,
    lineHeight: 1.2,
    textAlign: 'left'
  },
  title: {
    fontFamily: 'GTWalsheimProMedium',
    marginLeft: 12,
    marginTop: 0,
    marginBottom: 10,
    color: '#4F2D7F',
    fontSize: 13.5,
    lineHeight: 1.2,
    textAlign: 'left'
  },
  subtitle: {
    fontFamily: 'GTWalsheimProMedium',
    color: '#4F2D7F',
    fontSize: 9,
    margin: 12,
  },
  text: {
    marginTop: 0,
    marginLeft: 12,
    marginBottom: 5,
    fontFamily: 'GTWalsheimProLight',
    fontSize: 9,
    lineHeight: 1.2,
    textAlign: 'left',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    flexGrow: 1,
  },
  left: {
     width: '40%',//<- working alternative
  },
  leftCover: {
    width: '40%',//<- working alternative
    paddingTop: 50,
    marginLeft: -30,
  },

  right: {
    paddingLeft: 20,
    paddingTop: 5,
    width: '60%', //<- working alternative
  },
  rightCover: {
    height: '175%',
    width: '80%',
  },
  image: {
    marginVertical: 0,
    marginHorizontal: 0,
    width: '100%'
  },
  imageCover: {
    marginVertical: 0,
    marginHorizontal: 0,
    objectFit: 'cover',
  
  },
  chart: { 
    marginVertical: 0,
    marginLeft: 15,
    width: '100%'
  },
});


const ResultsPage = (props: Props) => {
  const resultsData = props.resultsData;
  const isCompleted = props.isCompleted;
  // Sum of each pair of sliders (pages 2 to 6).
  const pairedResults = props.pairedResults;
  // Total of each page (2 to 6).
  const totalResults = props.totalResults;
  // Total of Priority values.
  const totalPriority = props.totalPriority;
  // Total of Proficency values.
  const totalProficency = props.totalProficency;
  // Results for the Measurement oage (page 7).
  const measurementResults = props.measurementResults;
  // Result for Sustainable Score.
  const sustainableResult = props.sustainableResult;
  const sustainableFinance = props.sustainableFinance;
  // Results Page Header
  const sections = props.sections;
  const pageLabels = props.pageLabels;
  const pageMaxScores = props.pageMaxScores;
  const panelTitles = props.panelTitles;
  const resultHeader = sections[sections.length - 1];

  const compareByIndustry = props.compareByIndustry;
  const compareByJobFunc = props.compareByJobFunc;
  const compareByRevenue = props.compareByRevenue;

  function relativePercentage(num: number, max: number) {
    return Math.round((100 * num) / max);
  }

  const totalPairedScore = props.totalPairedScore;

  const R00_TotalPriority = totalPriority.reduce(
    (partialSum: any, a: any) => partialSum + a,
    0
  );

  const priorityScoreMax = 95;
  const priorityScorePercentage = relativePercentage(
    R00_TotalPriority,
    priorityScoreMax
  );

  const R00_TotalProficency = totalProficency.reduce(
    (partialSum: any, a: any) => partialSum + a,
    0
  );

  const proficencyScoreMax = 95;
  const proficencyScorePercentage = relativePercentage(
    R00_TotalProficency,
    proficencyScoreMax
  );

  const finalScore = totalPairedScore;
  const maxScore: number = 190;
  const finalScorePercentage = relativePercentage(finalScore, maxScore);

  //let loading = useRef(false);

  //start pdf work
  let resultsHeaderImg: any
  let resultsChart: any
  let benchmark: any
  let esg: any
  let climate: any
  let supply: any
  let people: any
  let tax: any
  let overall: any
  //const [loading, setLoading] = useState<boolean>(false);
  

  // console.log(Pdf); 
  // console.log(resultsHeaderImg); 
  // console.log(resultsChart);
  // console.log(esg); 
  // console.log(climate);

  const ResultsCover = (props: any) => (
    <Document>
      <Page size="A4" style={pdfStyles.cover} orientation="landscape">
        <View style={[pdfStyles.row, { height: 460 }]}>
          <View  style={pdfStyles.leftCover}>
            <View style={pdfStyles.border}>
              <Text style={pdfStyles.coverTitle1}>
                Mapping
              </Text>
              <Text style={pdfStyles.coverTitle1}>
                your route to
              </Text>
              <Text style={pdfStyles.coverTitle2}>
                ESG maturity.
              </Text>
              <Text style={pdfStyles.coverSubtitle1}>
                Results report for {resultsData[1].value}
              </Text>
              <Text style={pdfStyles.coverSubtitle2}>
                {resultsData[2].value} at {resultsData[4].value}
              </Text>
            </View>
          </View>
          <View style={pdfStyles.rightCover} >
            <Image style={{width: 520, height: 595, marginLeft: 40}} 
              src={coverShape}
              />
          </View>
        </View>
      </Page>
    </Document>
  );

  const ResultsCoverChart = (props: any) => (
    <Document>
      <Page size="A4" style={pdfStyles.page} orientation="landscape">
        <View style={[pdfStyles.row, { height: 450 }]}>
          <View  style={pdfStyles.left}>
              <Text style={pdfStyles.text}>
                Results
              </Text>
              <Text style={[pdfStyles.titleBold, { marginLeft: 12, marginBottom: 20, }]}>
                    Your route to ESG maturity
              </Text>
              <Text style={[pdfStyles.text, { width: '90%', marginBottom: 15, }]}>
                Before you can build on ESG in your organisation, you need to know where your business is today. This report helps you assess your business’ ESG maturity and benchmarks you against others in your job function, industry and company size bracket. 
              </Text>
              <Text style={[pdfStyles.text, { width: '90%', marginBottom: 15, }]}>
                The ESG maturity score is based on your answers regarding how likely you are to prioritise important ESG matters as well as how proficient you deem your organisation to be in addressing them. The original sample for benchmarking is 800 business leaders working at businesses from £50million - £1 billion across 18 industries.
              </Text>
              <View style={pdfStyles.border1}>
                  <Text style={pdfStyles.text}>
                    ESG maturity score
                  </Text>
                  <View  style={{ color: '#4F2D7F', fontFamily: 'GTWalsheimPro', marginLeft:12, flex: 0, flexDirection: 'row', flexGrow: 1,}}>
                    <Text style={{fontSize: 50, }}>
                      {finalScorePercentage}
                    </Text>
                    <Text style={{top: 32}}>
                      /100
                    </Text>
                  </View>
              </View>
          </View>
          <View  style={pdfStyles.right}>
            <Image 
              style={{width: 300, marginTop: -20 }}
              src={resultsChart}/>
            <Image 
              style={{width: 300}}
              src={benchmark}/>
          </View>
        </View>
      </Page>
    </Document>
  );

  const GeneratedResultsPdf = (props: any) => (
    <Document>
      <Page size="A4" style={pdfStyles.page} orientation="landscape">
        <View style={[pdfStyles.row, { height: 450 }]}>
          <View  style={pdfStyles.left}>
            <Text style={pdfStyles.title}>ESG strategy and reporting</Text>
            <Text style={pdfStyles.text}>
                To shape your ESG agenda, you must identify the priorities 
                most relevant to your organisation. These decisions will be 
                based on stakeholder expectations and actions should be 
                embedded into strategy and the systems used to monitor 
                progress.
            </Text>
            <Text style={pdfStyles.text}>
                Of all businesses we spoke to, only 35% agreed that their 
                stakeholders are ‘engaged’ with their ESG purpose. Gaining 
                support from leadership teams is business critical when 
                considering ESG strategies as your strategy needs to become 
                part of your culture.
            </Text>
            <Text style={pdfStyles.text}>
                It was promising to see that only 3% said that ESG is not a 
                business priority, and 16% were aware they need to make a 
                change but are not sure how. 53% were committed to their 
                ESG strategies and have started to implement some of their 
                initiatives across the organisation already, and 44% had a 
                dedicated member of the board responsible for ESG.
            </Text>
            <Text style={pdfStyles.text}>
                Business leaders tend to attribute importance to the impact 
                of ESG strategies on profitability above all else and more 
                than other function leaders. Personal understanding of how ESG 
                policies should be embedded in organisations is generally 
                high at 66%, and 63% are aiming to achieve ESG goals within 
                the next 2-3 years, though this may be difficult for some 
                organisations given the level of engagement from senior leadership.
            </Text>
            <Text style={pdfStyles.subtitle}>
                How we can help you
            </Text>
            <Text style={pdfStyles.text}>
                Is your ESG strategy clearly defined? We can help you pinpoint your key priorities, mitigate risks and identify and manage opportunities. Whether that’s with climate change and transition risks, environmental impacts, sustainable and ethical supply chains, inclusion and diversity, current and future tax implications or reporting, assurance and communication.
            </Text>
            <Link src="https://www.grantthornton.co.uk/services/environmental-social-and-governance-esg/esg-strategy-risk-and-opportunity-identification/">
              <Text style={pdfStyles.text}>Start shaping your ESG agenda</Text>
            </Link>
          </View>
        
          <View style={pdfStyles.right}>
            <Image  
                style={pdfStyles.chart}
              src={graphLegend}/>
            <Image 
              style={pdfStyles.chart}
              src={esg}
              />
          </View>
        </View>
      </Page>
      <Page size="A4" style={pdfStyles.page} orientation="landscape">
        <View style={pdfStyles.row}>
          <View style={pdfStyles.left}>
            <Text style={pdfStyles.title}>Climate strategies</Text>
            <Text style={pdfStyles.text}>
                Climate change and Net Zero is a ‘top’ market issue and 
                regulators and standard setters are aligning around common 
                reporting standards as agents of change. 
            </Text>
            <Text style={pdfStyles.text}>
                In response we are seeing:
            </Text>
            <Text style={pdfStyles.subtitle}>
                Government policy being focused on Net Zero 
            </Text>
            <Text style={pdfStyles.text}>
                In the UK climate-related reporting is mandatory for certain 
                large companies and LLPs from 6 April 2022. In the US, the 
                SEC has issued proposals for climate-related reporting for 
                registrants
            </Text>
            <Text style={pdfStyles.subtitle}>
                Funding being targeted at climate change
            </Text>
            <Text style={pdfStyles.text}>
                Opportunities are abounded for clients and investors to take 
                advantage of.
            </Text>
            <Text style={pdfStyles.subtitle}>
                The economy transitioning to Green and Net-Zero
            </Text>
            <Text style={pdfStyles.text}>
                Organisations within supply chains are increasingly being 
                required to transition to a low carbon footprint and potentially assess their 
                exposure to climate risk as a condition of maintaining their 
                preferred supplier status with larger customers.
            </Text>
            <Text style={pdfStyles.subtitle}>
                How we can help you
            </Text>
            <Text style={pdfStyles.text}>
                Building effective climate and energy strategies will help you meet reporting requirements, identify cost efficiencies and maintain your access to finance. Do you have the strategic understanding, data and processes to set and disclose metrics that assess and manage ESG-related risks and opportunities?
            </Text>
            <Link src="https://www.grantthornton.co.uk/services/environmental-social-and-governance-esg/esg-metrics-targets-and-disclosures/">
              <Text style={pdfStyles.text}>Drive efficiency from your climate strategy</Text>
            </Link>
          </View>
          <View style={pdfStyles.right}>
            <Image  
                style={pdfStyles.chart}
              src={graphLegend}/>
            <Image 
              style={pdfStyles.chart}
              src={climate}
              />
          </View>
        </View>
      </Page>
      <Page size="A4" style={pdfStyles.page} orientation="landscape">
        <View style={pdfStyles.row}>
          <View style={pdfStyles.left}>
            <Text style={pdfStyles.title}>People and social impact</Text>
            <Text style={pdfStyles.text}>
                Inclusion and diversity, equal pay, human rights and working conditions, labour standards, as well as attracting and retaining talent, have never been more important. For organisations seeking to lead on the ESG agenda, human capital considerations are board issues.
            </Text>
            <Text style={pdfStyles.subtitle}>
                How we can help you
            </Text>
            <Text style={pdfStyles.text}>
                Creating a purpose-led strategy that is embedded within your culture and drives inclusivity will help you attract and retain the talent you want and avoid the reputational damage you don’t. Ensuring ESG is embedded into decision-making through governance frameworks, leadership, and culture is a priority for an effective sustainability strategy.
            </Text>
            <Link src="https://www.grantthornton.co.uk/services/environmental-social-and-governance-esg/esg-governance-leadership-and-culture-framework/">
              <Text style={pdfStyles.text}>Get help to shape an inclusive strategy</Text>
            </Link>
          </View>
          <View style={pdfStyles.right}>
            <Image  
                style={pdfStyles.chart}
              src={graphLegend}/>
            <Image
              style={pdfStyles.chart}
              src={people}
              />
          </View>
        </View>
      </Page>
      <Page size="A4" style={pdfStyles.page} orientation="landscape">
        <View style={pdfStyles.row}>
          <View style={pdfStyles.left}>
            <Text style={pdfStyles.title}>Sustainable, ethical and secure supply chains</Text>
            <Text style={pdfStyles.text}>
                Supply chain sustainability and security can be core risk considerations for organisations, particularly where critical aspects of the supply chain are based overseas. Ethical risks are heightened as the complexity and geographic reach of a supply chain increases and transparency reduces.
            </Text>
            <Text style={pdfStyles.text}>
              Physical climate risks, modern slavery and working conditions, geopolitical risks, sanctions, bribery and corruption, trade compliance and export controls, access to and the sustainable use of natural resources or changes to the focus of taxation are all key considerations as governments look to green taxation to encourage sustainable change within their economies.
            </Text>
            <Text style={pdfStyles.subtitle}>
                How we can help you
            </Text>
            <Text style={pdfStyles.text}>
                How will you establish a sustainable supply chain that maximises efficiencies and profitability? Whether ethical, physical, tax-based or focused on the economics of your footprint, supply chain sustainability and security are core risk considerations. Understanding these risks will allow your business to champion transparent and ethical working practices and enable better decision-making.
            </Text>
            <Link src="https://www.grantthornton.co.uk/services/environmental-social-and-governance-esg/esg-driven-business-transition/">
              <Text style={pdfStyles.text}>Create a resilient supply chain</Text>
            </Link>
          </View>
          <View style={pdfStyles.right}>
            <Image  
                style={pdfStyles.chart}
              src={graphLegend}/>
            <Image
              style={pdfStyles.chart}
              src={supply}
              />
          </View>
        </View>
      </Page>
      <Page size="A4" style={pdfStyles.page} orientation="landscape">
        <View style={pdfStyles.row}>
          <View style={pdfStyles.left}>
            <Text style={pdfStyles.title}>ESG and tax</Text>
            <Text style={pdfStyles.text}>
                For some organisations, tax policy will itself be a driver behind ESG-related business change. Taxes on the use or production of commodities and products viewed as having negative externalities, such as carbon, non-recyclable waste and plastics are proliferating globally.
            </Text>
            <Text style={pdfStyles.text}>
              For other organisations, tax considerations are part of the broader ESG agenda but can add or erode value depending on how proactive an organisation is in including tax early. Non-compliance leads not only to costs in dealing with tax authorities but also potentially significant reputational damage with stakeholders and customers, leading to further value leakage.
            </Text>
            <Text style={pdfStyles.subtitle}>
                How we can help you
            </Text>
            <Text style={pdfStyles.text}>
                Tax will impact on all ESG driven business priorities. Whether you are horizon scanning for future policy changes around environmental taxes, or looking to maximise incentives and grants to improve return on investments, proactively addressing these considerations will help focus your time and resources.
            </Text>
            <Link src="https://www.grantthornton.co.uk/services/environmental-social-and-governance-esg/esg-risk-management/">
              <Text style={pdfStyles.text}>Start integrating tax into your ESG agenda</Text>
            </Link>
          </View>
          <View style={pdfStyles.right}>
            <Image  
                style={pdfStyles.chart}
              src={graphLegend}/>
            <Image
              style={pdfStyles.chart}
              src={tax}
              />
          </View>
        </View>
      </Page>
      <Page size="A4" style={pdfStyles.page} orientation="landscape">
        <View style={pdfStyles.row}>
          <View style={pdfStyles.left}>
            <Text style={pdfStyles.title}>Measurement</Text>
            <Text style={pdfStyles.text}>
                Don’t be afraid to ask hard questions about your business now and who you want to be in the future when setting your ESG KPIs. What you choose to measure and report on will ultimately help you to understand if your ESG strategy is working. 
            </Text>
            <Text style={pdfStyles.text}>
                Setting a clear reporting framework from the outset, considering all of your internal and external stakeholders priorities will help you to build a platform for success and focus your effort for impact.
            </Text>
            <Text style={pdfStyles.subtitle}>
                How we can help you
            </Text>
            <Text style={pdfStyles.text}>
                Do you have the strategic understanding, data and processes to optimise your ESG reporting? Both global bodies and internal stakeholders will be increasingly interested in the ESG metrics you set and disclose. Whether your reporting is voluntary or obligatory, you should be aligned with global expectations and ensure compliance.  
            </Text>
            <Link src="https://www.grantthornton.co.uk/services/environmental-social-and-governance-esg/esg-metrics-targets-and-disclosures/">
              <Text style={pdfStyles.text}>Get support to set and measure your ESG targets</Text>
            </Link>
          </View>
          <View style={pdfStyles.right}>
            <Image  
                style={pdfStyles.chart}
              src={graphLegend}/>
            <Image 
              style={pdfStyles.chart}
              src={overall}
              />
          </View>
        </View>
      </Page>
    </Document>  
  );

   // console.log("Results Page > resultHeader", resultHeader);
    // console.log("Results Page > totalPairedScore", totalPairedScore);
    // console.log("Results Page > sections", sections);
    // console.log("Results Page > pageLabels", pageLabels);
    // console.log("Results Page > pairedResults", pairedResults);
    // console.log("Results Page > panelTitles", panelTitles);
    // console.log("Results Page > totalResults", totalResults);
    // console.log("Results Page > R00_TotalPriority", R00_TotalPriority);
    // console.log("Results Page > R00_TotalProficency", R00_TotalProficency);
    // console.log("Results Page > finalScore", finalScore);
    // console.log("Results Page > finalScorePercentage", finalScorePercentage);

    // console.log("Results Page > pageMaxScores", pageMaxScores);
    // console.log("Results Page > totalPriority", totalPriority);
    // console.log("Results Page > totalProficency", totalProficency);

    // console.log("Results Page > compareByIndustry", compareByIndustry);
    // console.log("Results Page > compareByJobFunc", compareByJobFunc);
    // console.log("Results Page > compareByRevenue", compareByRevenue);
    // Calculate Comparison scores
    const formatComparisongData = (data: any) => {
      let totalRelPriority = data.R00_TotalPriority;
      let totalRelProficency = data.R00_TotalProficency;
      let pairedRelResults = [
        [data.P01, data.P02, data.P03, data.P04],
        [data.P05, data.P06, data.P07],
        [data.P08, data.P09, data.P10, data.P11],
        [data.P12, data.P13, data.P14, data.P15, data.P16],
        [data.P17, data.P18, data.P19],
        [data.R07_ESGMeasurement],
      ];
      let totalRelResults = [
        data.R01_ESGStrategy,
        data.R02_ESGClimateChangeStrategies,
        data.R03_ESGSusEthSecSupplyChain,
        data.R04_ESGPeopleandSocialImpact,
        data.R05_ESGTax,
        data.R07_ESGMeasurement,
      ];
      return {
        totalRelPriority: totalRelPriority,
        totalRelProficency: totalRelProficency,
        pairedRelResults: pairedRelResults,
        totalRelResults: totalRelResults,
      };
    };
    const industryResults = formatComparisongData(compareByIndustry);
    const jobFuncResults = formatComparisongData(compareByJobFunc);
    const revenueResults = formatComparisongData(compareByRevenue);
    // Percentage relative Priority and Peroficency for compared data
    const industryPriorityScorePercentage = relativePercentage(
      industryResults.totalRelPriority,
      priorityScoreMax
    );
    const jobFuncPriorityScorePercentage = relativePercentage(
      jobFuncResults.totalRelPriority,
      priorityScoreMax
    );
    const revenuPriorityScorePercentage = relativePercentage(
      revenueResults.totalRelPriority,
      priorityScoreMax
    );

    const industryProficencyScorePercentage = relativePercentage(
      industryResults.totalRelProficency,
      proficencyScoreMax
    );

    const jobFuncProficencyScorePercentage = relativePercentage(
      jobFuncResults.totalRelProficency,
      proficencyScoreMax
    );

    const revenueProficencyScorePercentage = relativePercentage(
      revenueResults.totalRelProficency,
      proficencyScoreMax
    );

    // Calcultate Priority Area
    let priorityArray = [];
    for (let i = 0; i < totalPriority.length; i++) {
      priorityArray.push(totalPriority[i] - totalProficency[i]);
    }

    // Get the highest priority value.
    const maxpriorityArray = Math.max(...priorityArray);
    // Get the position of the highest priority value inside the array..
    const sectionIndex = priorityArray.indexOf(maxpriorityArray);
    // Get the page matching the highest priority value.
    const PriorityScore = pageLabels[sectionIndex].label;
    // console.log(PriorityScore)
    
    // Inti total measuremenet array.
    const measurementTotal = [];
    // Set the measurement total first and only array item to the measurmeent score.
    measurementTotal[0] = measurementResults[0];
    // Add the measurement score to the pairedResults array.
    const completePairs = pairedResults.concat(measurementTotal);
    // Add the measurement score to the totalResults array.
    const newTotalResults = totalResults.concat(measurementTotal);

    // DEBUG
    // console.log("Results Page > priorityArray", priorityArray);
    // console.log("Results Page > maxpriorityArray", maxpriorityArray);
    // console.log("Results Page > sectionIndex", sectionIndex);
    // console.log("Results Page > measurementTotal", measurementTotal);
    // console.log("Results Page > measurementResults", measurementResults);
    // console.log("Results Page > completePairs", completePairs);
    // console.log("Results Page > newTotalResults", newTotalResults);

    // console.log("Results Page > industryResults", industryResults);
    // console.log("Results Page > jobFuncResults", jobFuncResults);
    // console.log("Results Page > revenueResults", revenueResults);

    // Testing pdf generation and upload inside useeffect
  let mergedPdf: any;
  useEffect(() => {
    
    window.scrollTo(0, 0);
    
    const genPDF = async () => {
      
     
       //scrape for screen shots
      const fetchResultsSVG = async () => {
        const resultsSVG = document.querySelector<HTMLElement>('.results-chart');
        if (!resultsSVG) return;
        const resultsSVGDataURL = await htmlToImage.toPng(resultsSVG);
        
          return resultsSVGDataURL;
      };

      const fetchBenchmark = async () => {
        const benchmark = document.querySelector<HTMLElement>('.benchmark');
        if (!benchmark) return;
        const benchmarkDataURL = await htmlToImage.toPng(benchmark);
       
          return benchmarkDataURL;
      };
      
      const fetchESG = async () => {
        const ESGStrategy = document.querySelector<HTMLElement>('.result-0');
        //console.log(ESGStrategy);
        if (!ESGStrategy) return;
        const ESGStrategyDataURL = await htmlToImage.toJpeg(ESGStrategy);
        
          return ESGStrategyDataURL;
      };
      const fetchClimate = async () => {
        const ClimateChange = document.querySelector<HTMLElement>('.result-1');
        if (!ClimateChange) return;
        const ClimateChangeDataURL = await htmlToImage.toJpeg(ClimateChange);
        
          return ClimateChangeDataURL;
      };
      const fetchSupply = async () => {
        const Supply = document.querySelector<HTMLElement>('.result-2');
        if (!Supply) return;
        const SupplyDataURL = await htmlToImage.toJpeg(Supply);
        
          return SupplyDataURL;
      };
      const fetchPeople = async () => {
        const People = document.querySelector<HTMLElement>('.result-3');
        if (!People) return;
        const PeopleDataURL = await htmlToImage.toJpeg(People);
        
          return PeopleDataURL;
      };
      const fetchTax = async () => {
        const Tax = document.querySelector<HTMLElement>('.result-4');
        if (!Tax) return;
        const TaxDataURL = await htmlToImage.toJpeg(Tax);
        
          return TaxDataURL;
      };
      const fetchOverall = async () => {
        const Overall = document.querySelector<HTMLElement>('.result-5');
        if (!Overall) return;
        const OverallDataURL = await htmlToImage.toJpeg(Overall);
      
          return OverallDataURL;
      };
      
      [resultsChart, benchmark, esg, climate, supply, people, tax, overall] = await Promise.all([
        fetchResultsSVG(), 
        fetchBenchmark(),
        fetchESG(),
        fetchClimate(),
        fetchSupply(),
        fetchPeople(),
        fetchTax(),
        fetchOverall()
      ])

        //start pdf generation
        const cover = <ResultsCover />;
        const coverChart = <ResultsCoverChart />;
        const doc = <GeneratedResultsPdf />; 
        const coverPdf = pdf();
        const asPdf = pdf();
        const coverChartPdf = pdf();
        asPdf.updateContainer(doc);
        coverPdf.updateContainer(cover);
        coverChartPdf.updateContainer(coverChart);

        //console.log(PriorityScore);
        let sustainable;
        if (sustainableFinance) {
          
          switch (sustainableResult) {
            default:
              sustainable = pdfSustainable1;
              break;
            case "The impact of ESG is having an increasing effect across all sectors on pricing and credit availability. This may not immediately restrict lender appetite but is likely to do so in the relatively near future as lenders prioritise ESG factors as part of their decisioning process. <br /><br />Considering a Sustainability Linked Loan at your next debt raise/refinance can help you achieve your overall financing needs.<br /><br />You should consider how a transition to sustainable finance for your business will help you to enhance your ESG commitments and achieve better pricing on loans or bonds.":
              sustainable = pdfSustainable1;
              break;
            case "Lenders' risk appetite is increasingly influenced by borrowers' ESG credentials and the changes can be difficult to keep track of if you refinance infrequently.<br /><br />We know that 57% of lenders have an ESG lending strategy in place and the proportion is growing. The level of pricing benefit varies from one lender to another.<br /><br />It is an increasingly competitive market and it is becoming more important to have a well-developed story around your transition to net zero before engaging with lenders, as lenders are more and more concerned about greenwashing.":
              sustainable = pdfSustainable2;
              break;
            case "Lenders' risk appetite is being influenced by borrowers' ESG credentials and the position is evolving. It can be difficult to keep track of if your refinancing only takes place infrequently. It is important that you stay as attractive as possible to your stakeholders and investors by continuing to develop your ESG credentials.<br /><br />We know that 57% of lenders have an ESG lending strategy in place. And striking the balance between ambitious and realistic ESG KPIs will help you continue to access the most favourable borrowing terms available.":
              sustainable = pdfSustainable3;
              break;
          }
        }
        //console.log(sustainable);
        let priority;
        switch (PriorityScore) {
          default:
            priority = pdfESGStrategy;
            break;
          case "ESG strategy and reporting":
            priority = pdfESGStrategy;
            break;
          case "Supply chain":
            priority = pdfSupply;
            break;
          case "Climate":
            priority = pdfClimate;
            break;
          case "People and social impact":
            priority = pdfPeople;
            break;
          case "ESG and Tax":
            priority = pdfTax;
            break;
        }
        
        let resultsCover = await coverPdf.toBlob();
        let pdfBlob = pdfReport; 
        let foreword = pdfForeword;
        let resultsBlob = await asPdf.toBlob();
        let resultsCoverChart = await coverChartPdf.toBlob();

        //check if sustainableResult exists and if so add the corect pdf to array 
        let pdfArray: any = [];
        if (sustainable) {
          pdfArray = [resultsCover, foreword, resultsCoverChart, resultsBlob, sustainable, pdfBlob]
        } else {
          pdfArray = [resultsCover, foreword, resultsCoverChart, resultsBlob, pdfBlob]
        }
        // let pdfArray = [resultsCover, foreword, resultsCoverChart, priority, resultsBlob, pdfBlob]
      
       
        let merger = new PDFMerger();
        //await Promise.all([resultsCover, resultsBlob, foreword,  pdfBlob].map(async (file) => await merger.add(file, null)));
     
        for (const file of pdfArray) {
          await merger.add(file)
           .catch(console.error);
        }
        
        mergedPdf = await merger.saveAsBlob();

        // Start upload of pdf using cloudinary
       
        const file = mergedPdf;
       
        var formdata: any = new FormData();

        formdata.append("file", file, `${resultsData[1].value} ${resultsData[2].value} ESG maturity index report.pdf`);
        formdata.append("cloud_name", "da8yug5n2");
        formdata.append("upload_preset", "esg_tool");


        let res = await fetch(
          "https://api.cloudinary.com/v1_1/da8yug5n2/auto/upload",
          {
            method: "post",
            mode: "cors",
            body: formdata,
          }
        );

      let json = await res.json();
      console.log(JSON.stringify(json.secure_url));

       
    };
    genPDF()
    // make sure to catch any error
    .catch(console.error);
    
    return () => {
      // this now gets called when the component unmounts

    };
  }, []);
  if (isCompleted) {

    const generatePDF = () => {

        saveAs(mergedPdf, `${resultsData[1].value} ${resultsData[2].value} ESG maturity index report.pdf`);

    };

    

    return (
      
      <>
      {/* {loading === true &&
        <div>if true show</div>
      } */}
      {/* <SpinnerCircular enabled={loading} size={50} thickness={100} speed={100} color="#36ad47" /> */}
        <div className="flex md:flex-row flex-col justify-between pt-12 pb-12">
          <ReactSVG
            className="mr-3"
            src={grantThornton}
          />
          
          <div className="mt-8 px-4 border border-gray lg:mt-4 rounded-lg inline-block">
            {/* <span className="block p-2">Download</span>
            <hr /> */}
            <a className="text-purple flex">
              <ReactSVG
                className="mr-3 mt-2"
                src={download}
              />
              <span 
                className="block p-2 cursor-pointer"
                onClick={generatePDF}
              >Download results</span>
            </a>
          </div>
        </div>
        <hr />
        <div className="flex flex-wrap overflow-hidden">
          <div className="w-full overflow-hidden md:mt-4 md:px-4 md:w-1/2">
            <header className="pt-8 lg:pt-20">
              <span className="font-body text-primary">
                {resultHeader.label}
              </span>
              <h1 className="font-headline text-primary">
                {resultHeader.title}
              </h1>
              <p className="font-body text-black" dangerouslySetInnerHTML={{ __html: resultHeader.desc }}></p>
              <div className="mt-6 border border-gray p-4 pb-0 rounded-lg font-headline text-primary inline-block">
                <span className="block font-body text-stone-500 mb-2">
                  ESG maturity score
                </span>
                {finalScorePercentage}
                <span className="font-subheading">/100</span>
              </div>
            </header>
          </div>
          <div className="w-full h-auto overflow-hidden md:mt-4 md:px-4 md:w-1/2">
            <ResultsChart
              className="w-full h-auto pt-12 mb-12 md:pt-24 md:mb-0"
              priScore={priorityScorePercentage}
              proScore={proficencyScorePercentage}
              ind_PriScore={industryPriorityScorePercentage}
              ind_ProScore={industryProficencyScorePercentage}
              job_PriScore={jobFuncPriorityScorePercentage}
              job_ProScore={jobFuncProficencyScorePercentage}
              rev_PriScore={revenuPriorityScorePercentage}
              rev_ProScore={revenueProficencyScorePercentage}
            />
            <div className="pl-5">
              <div className="flex flex-row items-center">
                <span className="bg-primary rounded-full h-3 w-3 block mr-2"></span>
                <span className="font-bold text-primary">
                  Your ESG maturity score - PR: {R00_TotalPriority}, PF: {" "}
                  {R00_TotalProficency}
                </span>
              </div>
              <div className="flex flex-row pb-8">
                <div className="flex flex-row items-center">
                  <span className="bg-teal rounded-full h-3 w-3 block mr-2"></span>{" "}
                  <span className="mr-2 text-teal">Industry</span>
                </div>
                <div className="flex flex-row items-center">
                  <span className="bg-gold rounded-full h-3 w-3 block mr-2"></span>{" "}
                  <span className="mr-2 text-gold">Job function</span>
                </div>
                <div className="flex flex-row items-center">
                  <span className="bg-red rounded-full h-3 w-3 block mr-2"></span>{" "}
                  <span className="text-red"> Annual revenue</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:my-4 md:px-4">
          <hr />
          <h2 className="font-subheading text-primary mt-4">
            Your maturity score breakdown
          </h2>
          {/* <div className="mt-4 border border-gray p-4 pb-0 mb-10 rounded-lg font-headline text-primary inline-block">
            <span className="block text-[20px] text-stone-500">
              Priority area
            </span>
            <span className="font-subheading">{PriorityScore}</span>
          </div> */}
          <Tabs className="mb-16">
            <TabList>
              {pageLabels.map((page: any, index: number) => (
                <Tab key={uuid()}>{page.label}</Tab>
              ))}
            </TabList>
            {pageLabels.map((page: any, index: number) => (
              <TabPanel key={uuid()} className="">
                {/* <img src={graphLegend} /> */}
                <div className="w-full mt-12 mb-8">
                  <div className="flex-row align-middle hidden lg:flex">
                      <ReactSVG
                        className="mr-2"
                        src={purpleCircle}
                      />
                      <span className="mr-16">Your score</span>
                      <span>Benchmark comparisons -</span>
                      <ReactSVG
                        className="mr-2 ml-4"
                        src={tealCircle}
                      />
                      <span>Industry</span>
                      <ReactSVG
                        className="mr-2 ml-8"
                        src={yellowCircle}
                      />
                      <span>Job function</span>
                      <ReactSVG
                        className="mr-2 ml-8"
                        src={redCircle}
                      />
                      <span>Company size</span>
                  </div>
                  <div className="flex flex-col lg:hidden">
                    <div className="flex flex-row pb-4">
                      <ReactSVG
                        className="mr-2"
                        src={purpleCircle}
                      />
                      <span className="mr-16">Your score</span>
                    </div>
                    <span>Benchmark comparisons -</span>
                    <div className="flex flex-row pb-4 pt-4">
                      <ReactSVG
                        className="mr-2 "
                        src={tealCircle}
                      />
                      <span>Industry</span>
                    </div>
                    <div className="flex flex-row pb-4">
                      <ReactSVG
                        className="mr-2"
                        src={yellowCircle}
                      />
                      <span>Job function</span>
                    </div>
                    <div className="flex flex-row pb-4">
                      <ReactSVG
                        className="mr-2 "
                        src={redCircle}
                      />
                      <span>Company size</span>
                    </div>
                  </div>
                </div>
                {/* <span key={uuid()} className="font-body block mt-8 mb-8">
                  Compare your journey by Industry, Job Title, and Company Size
                </span> */}
                <h2 key={uuid()} className="font-body font-bold mb-4 pt-4">
                  {page.label === "ESG strategy and reporting"  ? (
                   <div>Overall <span>{page.label}{" "}</span></div>
                  ) 
                  : page.label === "ESG and tax" ? (
                   <div>Overall <span>{page.label}{" "}</span></div>
                  ) 
                  :
                  (
                    <div>Overall <span className="lowercase">{page.label}{" "}</span></div>
                  )}
                </h2>
                <ScoreProgress
                  key={uuid()}
                  num={relativePercentage(
                    newTotalResults[index],
                    pageMaxScores[index].maxValue
                  )}
                  color="#4f2d7f"
                />
                <ScoreProgress
                  key={uuid()}
                  num={relativePercentage(
                    industryResults.totalRelResults[index],
                    pageMaxScores[index].maxValue
                  )}
                  color="#00A4B3"
                />
                <ScoreProgress
                  key={uuid()}
                  num={relativePercentage(
                    jobFuncResults.totalRelResults[index],
                    pageMaxScores[index].maxValue
                  )}
                  color="#FFC23D"
                />
                <ScoreProgress
                  key={uuid()}
                  num={relativePercentage(
                    revenueResults.totalRelResults[index],
                    pageMaxScores[index].maxValue
                  )}
                  color="#FF5149"
                />
                {index < 5 &&
                  panelTitles[index].map((title: any, num: number) => (
                    <div key={uuid()}>
                      <h3 className="font-body mb-4 mt-12" key={uuid()} dangerouslySetInnerHTML={{ __html: title.substring(13) }}>
                        
                      </h3> 
                      <ScoreProgress
                        key={uuid()}
                        num={relativePercentage(
                          completePairs[index][num],
                          10
                        )}
                        color=""
                      />
                      <ScoreProgress
                        key={uuid()}
                        num={relativePercentage(
                          industryResults.pairedRelResults[index][num],
                          10
                        )}
                        color="#00A4B3"
                      />
                      <ScoreProgress
                        key={uuid()}
                        num={relativePercentage(
                          jobFuncResults.pairedRelResults[index][num],
                          10
                        )}
                        color="#FFC23D"
                      />
                      <ScoreProgress
                        key={uuid()}
                        num={relativePercentage(
                          revenueResults.pairedRelResults[index][num],
                          10
                        )}
                        color="#FF5149"
                      />
                    </div>
                  ))}
              </TabPanel>
            ))}
          </Tabs>
          {sustainableFinance > 0 && (
            <>
              <hr />
              <h2 className="font-subheading text-primary mt-4 mb-6">
                Sustainable finance
              </h2>
              <div className="sm:flex sm:flex-row items-start">
                <div className="sm:w-3/4">
                  <p className="font-body pr-4">
                    {parse(sustainableResult as string)}
                  </p>
                </div>
                <ReactSVG
                  beforeInjection={(svg) => {
                    svg.classList.add("w-full", "h-auto");
                  }}
                  className="sm:w-1/4 mt-8 md:mt-0 p-8 border border-gray p-4 rounded-lg"
                  src={sustainabilityIcon}
                />
              </div>
            </>
          )}
          {/* <hr className="mt-8"/>
          <h2 className="font-subheading text-primary mt-4 mb-6">
            Our ESG experience
          </h2>
          <CaseStudies /> */}
          <div>
            <hr className="mt-8"/>
            <h2 className="font-subheading text-primary mt-4 mb-6">
              Discuss your findings
            </h2>
            <div className="w-full">
              <p className="font-body">
                At Grant Thornton, ESG is core to our purpose: “Doing what’s right ahead of what’s easy”. We have a comprehensive ESG agenda and are committed to supporting our clients to achieve their ESG goals. Get in touch to find out how we can help you secure tangible value from your ESG agenda. 
              </p>
            </div>
            <div className='flex flex-row'>
              <div></div>
            </div>
            <Experts />
            <div className="w-full">
              <p className="font-body">
                <br/>
                <i>Before we enter any engagements there are some internal processes and checks we need to complete to ensure our independence.</i>
              </p>
            </div>
          </div>
        </div>
        <div id="pdf" className="overflow-hidden absolute top-[-9999px] left-[-9999px]">
          <div className="flex flex-wrap overflow-hidden">
            <div className="w-full h-auto overflow-hidden  md:w-1/2">
              <ResultsChart
                className="w-full h-auto results-chart md:mb-0"
                priScore={priorityScorePercentage}
                proScore={proficencyScorePercentage}
                ind_PriScore={industryPriorityScorePercentage}
                ind_ProScore={industryProficencyScorePercentage}
                job_PriScore={jobFuncPriorityScorePercentage}
                job_ProScore={jobFuncProficencyScorePercentage}
                rev_PriScore={revenuPriorityScorePercentage}
                rev_ProScore={revenueProficencyScorePercentage}
              />
              <div className="pl-5 benchmark w-[500px]">
                <div className="flex flex-row items-center">
                  <span className="bg-primary rounded-full h-3 w-3 block mr-2"></span>
                  <span className="font-bold text-primary">
                    Your ESG maturity score - PR: {R00_TotalPriority}, PF:{" "}
                    {R00_TotalProficency}
                  </span>
                </div>
                <div className="flex flex-row">
                  <div className="flex flex-row items-center">
                    <span className="bg-teal rounded-full h-3 w-3 block mr-2"></span>{" "}
                    <span className="mr-2 text-teal">Industry</span>
                  </div>
                  <div className="flex flex-row items-center">
                    <span className="bg-gold rounded-full h-3 w-3 block mr-2"></span>{" "}
                    <span className="mr-2 text-gold">Job function</span>
                  </div>
                  <div className="flex flex-row items-center">
                    <span className="bg-red rounded-full h-3 w-3 block mr-2"></span>{" "}
                    <span className="text-red"> Annual revenue</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:my-4 md:px-4">
            <Tabs className="mb-16 w-[1088px] bg-white" forceRenderTabPanel>
            <TabList>
              {pageLabels.map((page: any, index: number) => (
                <Tab key={uuid()}>{page.label}</Tab>
              ))}
            </TabList>
              {pageLabels.map((page: any, index: number) => (
                <TabPanel key={uuid()} className={`result-graph bg-white result-${index}`}>
                  {/* <h1 key={uuid()} className="font-heading font-bold mb-4 pt-4">
                    {page.label}{" "}
                  </h1> */}
                  <h2 key={uuid()} className="font-body font-bold mb-4 pt-4">
                    {page.label === "ESG strategy and reporting" ? (
                    <div>Overall <span>{page.label}{" "}</span></div>
                    ) : (
                      <div>Overall <span className="lowercase">{page.label}{" "}</span></div>
                    )}
                  </h2>
                  <ScoreProgress
                    key={uuid()}
                    num={relativePercentage(
                      newTotalResults[index],
                      pageMaxScores[index].maxValue
                    )}
                    color="#4f2d7f"
                  />
                  <ScoreProgress
                    key={uuid()}
                    num={relativePercentage(
                      industryResults.totalRelResults[index],
                      pageMaxScores[index].maxValue
                    )}
                    color="#00A4B3"
                  />
                  <ScoreProgress
                    key={uuid()}
                    num={relativePercentage(
                      jobFuncResults.totalRelResults[index],
                      pageMaxScores[index].maxValue
                    )}
                    color="#FFC23D"
                  />
                  <ScoreProgress
                    key={uuid()}
                    num={relativePercentage(
                      revenueResults.totalRelResults[index],
                      pageMaxScores[index].maxValue
                    )}
                    color="#FF5149"
                  />
                  {index < 5 &&
                    panelTitles[index].map((title: any, num: number) => (
                      <div key={uuid()}>
                        <h3 className="font-body mb-4 mt-12" key={uuid()} dangerouslySetInnerHTML={{ __html: title.substring(13) }}>
                        
                        </h3> 
                        <ScoreProgress
                          key={uuid()}
                          num={relativePercentage(
                            completePairs[index][num],
                            10
                          )}
                          color=""
                        />
                        <ScoreProgress
                          key={uuid()}
                          num={relativePercentage(
                            industryResults.pairedRelResults[index][num],
                            10
                          )}
                          color="#00A4B3"
                        />
                        <ScoreProgress
                          key={uuid()}
                          num={relativePercentage(
                            jobFuncResults.pairedRelResults[index][num],
                            10
                          )}
                          color="#FFC23D"
                        />
                        <ScoreProgress
                          key={uuid()}
                          num={relativePercentage(
                            revenueResults.pairedRelResults[index][num],
                            10
                          )}
                          color="#FF5149"
                        />
                      </div>
                    ))}
                    
                </TabPanel>
              ))}
            </Tabs>
            {sustainableFinance > 0 && (
              <>
                <hr />
                <h2 className="font-subheading text-primary mt-4 mb-6">
                  Sustainable Finance
                </h2>
                <span className="block font-body text-stone-500 mb-2">
                  Your ESG lending strategy is:
                </span>
                <div className="sm:flex sm:flex-row items-start">
                  <div className="sm:w-3/4">
                    <p className="font-caption">
                      {parse(sustainableResult as string)}
                    </p>
                  </div>
                  <ReactSVG
                    beforeInjection={(svg) => {
                      svg.classList.add("w-full", "h-auto");
                    }}
                    className="sm:w-1/4 p-8 border border-gray p-4 rounded-lg"
                    src={sustainabilityIcon}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-8">
          <hr />
          {/* <h2 className="font-subheading text-primary mt-4 mb-6">
            <a onClick={Merger}>Download Results</a>
          </h2> */}
          <h2 className="font-subheading text-primary mt-4 mb-6">
              Download your full report with further insights
            </h2>
          <div className="px-4 border border-gray rounded-lg inline-block">
            {/* <span className="block p-2">Download</span>
            <hr /> */}
            
            <a className="text-purple flex">
              <ReactSVG
                className="mr-3 mt-2"
                src={download}
              />
              <span 
                className="block p-2 cursor-pointer"
                onClick={generatePDF}
              >Download results</span>
            </a>
          </div>
        </div>
      </>
    );
  }
};

export { ResultsPage };
