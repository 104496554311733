import React from "react";

type Props = {
  sections: any;
  currentIndex: number;
};

let sectionIndex: number;

const ProgressBar = (props: Props) => {
  // Grouping page indexes into sections.
  if (props.currentIndex === 0 || props.currentIndex === 1) {
    sectionIndex = props.currentIndex;
  } else if (props.currentIndex >= 2 && props.currentIndex <= 6) {
    sectionIndex = 2;
  } else if (props.currentIndex === 7) {
    sectionIndex = 3;
  } else if (props.currentIndex === 8) {
    sectionIndex = 4;
  }
  const sections = props.sections;
  const sectionsNo: number = sections.length;
  const progressFraction: number = 100 / sectionsNo;
  // const currentIndex = sections.findIndex((section: any) => {
  //   return section.progressLabel === props.current;
  // });
  const progressWidth: number = progressFraction * sectionIndex + 10;
  // console.log("sectionIndex", sectionIndex);
  const styles = {
    progress: {
      width: progressWidth + "%",
    },
  } as const;

  return (
    <div className="progressBar bg-white mb-16 ">
      <ul className="progress__legend flex flex-row justify-evenly w-full">
        {props.sections.map((section: any, i: number) => (
          <li
            key={"section-" + i}
            className={`pt-8 pb-4 text-primary ${
              props.sections[sectionIndex].title === section.title
                ? "font-bold"
                : "hidden sm:block"
            }`}
          >
            <span>{section.progressLabel}</span>
          </li>
        ))}
      </ul>
      <div className="progress w-full bg-tealLight">
        <div className={`h-3 bg-teal relative `} style={styles.progress}></div>
      </div>
    </div>
  );
};

export { ProgressBar };
