// Custom SurvejJS CSS classes.
// See also https://amethyst.grantthornton.global/1.00/
export const surveyCssClasses = {
  row: "sd-row sd-clearfix !mt-10",
  root: " ",
  title: "",
  actionBar: {
    root: "sd-action-bar !mt-16 relative flex-wrap",
  },
  bodyNavigationButton:
    "py-4 px-10 sm:px-16 bg-white text-purple transition  border border-primary font-button cursor-pointer hover:bg-primary hover:text-white",
  navigation: {
    complete: "sd-navigation__complete-btn",
    prev: "sd-navigation__prev-btn",
    next: "sd-navigation__next-btn",
    start: "sd-navigation__start-btn",
    preview: "sd-navigation__preview-btn",
    edit: "",
  },
  page: {
    root: " sd-body__page !pt-0",
    title: "font-subheading text-purple mt-10 mb-6",
    description: "page-descrription",
  },
  question: {
    mainRoot: "",
    withFrame: "",
    title: "font-smallcaps pb-4",
    number: "",
  },
  list: {
    root: "sv-list__container sd-list",
    item: "sv-list__item sd-list__item",
    itemBody: "sv-list__item-body sd-list__item-body",
    itemSelected: "sv-list__item--selected sd-list__item--selected",
  },
  paneldynamic: {
    title: "",
  },
  panel: {
    container: "border border-gray rounded-xl pl-4 pt-8 pr-6 sm:p-10 sm:pr-12 pb-4 md:pb-0",
    title: "font-smallcaps",
    description: "font-caption",
    withFrame: "",
  },
  text: {
    root: "form-field",
  },
  checkbox: {
    root: "sd-selectbase",
    rootRow: "sd-selectbase--row",
    rootMultiColumn: "sd-selectbase--multi-column",
    item: "form-checkbox",
    itemOnError: "sd-item--error",
    itemSelectAll: "sd-checkbox--selectall",
    itemNone: "sd-checkbox--none",
    itemDisabled: "sd-item--disabled sd-checkbox--disabled",
    itemChecked: "sd-item--checked sd-checkbox--checked",
    itemHover: "sd-item--allowhover sd-checkbox--allowhover",
    itemInline: "sd-selectbase__item--inline",
    label: "form-checkbox-label",
    labelChecked: "",
    itemControl: "form-checkbox-input",
    itemDecorator: "sd-item__svg sd-checkbox__svg",
    itemSvgIconId: "#icon-v2check",
    controlLabel: "form-checkbox-text",
    materialDecorator: "!hidden",
    other: "sd-input sd-comment sd-selectbase__other",
    column: "sd-selectbase__column",
  },
  radiogroup: {
    root: "sd-selectbase",
    rootRow: "sd-selectbase--row",
    rootMultiColumn: "sd-selectbase--multi-column",
    item: "sd-item form-radio sd-selectbase__item",
    itemOnError: "sd-item--error",
    itemInline: "sd-selectbase__item--inline",
    label: "form-radio-label",
    labelChecked: "",
    itemDisabled: "sd-item--disabled sd-radio--disabled",
    itemChecked: "sd-item--checked sd-radio--checked",
    itemHover: "sd-item--allowhover sd-radio--allowhover",
    itemControl: "sd-visuallyhidden sd-item__control form-radio-input",
    itemDecorator: "sd-item__svg sd-radio__svg",
    controlLabel: "form-radio-text",
    materialDecorator: "sd-item__decorator sd-radio__decorator",
    other: "sd-input sd-comment sd-selectbase__other",
    clearButton: "",
    column: "sd-selectbase__column",
  },
  dropdown: {
    root: "sd-selectbase",
    small: "sd-row__question--small",
    selectWrapper: "",
    other: "sd-input sd-comment sd-selectbase__other",
    onError: "sd-input--error",
    label: "sd-selectbase__label",
    item: "sd-item sd-radio sd-selectbase__item",
    itemDisabled: "sd-item--disabled sd-radio--disabled",
    itemChecked: "sd-item--checked sd-radio--checked",
    itemHover: "sd-item--allowhover sd-radio--allowhover",
    itemControl: "sd-visuallyhidden sd-item__control sd-radio__control",
    itemDecorator: "sd-item__svg sd-radio__svg",
    cleanButton: "sd-dropdown_clean-button",
    cleanButtonSvg: "sd-dropdown_clean-button-svg",
    cleanButtonIconId: "icon-clear",
    control: "select",
    controlInputFieldComponent: "sd-dropdown__input-field-component",
    controlValue: "select-toggle",
    controlDisabled: "sd-input--disabled",
    controlEmpty: "sd-dropdown--empty",
    controlLabel: "sd-item__control-label",
    filterStringInput: "select-toggle-text",
    materialDecorator: "sd-item__decorator sd-radio__decorator",
  },
};
