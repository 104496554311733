export const json = {
  title: "Your ESG Maturity",
  completedHtml:
    "<h3 class='text-primary font-subheading'>Thank you. You have already taken the survey.</h3>",
  calculatedValues: [
    {
      name: "sections",
      expression: {
        sections: [
          {
            progressLabel: "Welcome",
            // label: "ESGPS",
            title: "Mapping your route to ESG maturity.",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            progressLabel: "Your business",
            // label: "Section 1",
            title: "Your business",
            desc: "<p>Please provide the details below for you to receive a copy of your ESG maturity report. Your details will be used in line with Grant Thornton UK LLP’s privacy policy.</p><br/><p>Before we enter any engagements there are some internal processes and checks we need to complete to ensure our independence.</p>",
          },
          {
            progressLabel: "Your ESG maturity",
            // label: "",
            title: "Your ESG maturity",
            desc: "",
          },
          {
            progressLabel: "Measurement and finance",
            // label: "",
            title: "Measurement and finance",
            desc: "<p>Businesses collecting more data have a better platform to track their ESG successes. Measurement underpins strong reporting and provides the basis for understanding if your business ESG strategy is working – which we believe is a signifier of maturity.</p><br/><p>After tremendous growth over the past 12-18 months, ESG-linked financing is widespread and now business-as-usual in some parts of the debt world. It can allow your organisation to continue and enhance its ESG commitments and to achieve better pricing on its loans or bonds. </p>",
          },
          {
            progressLabel: "Results",
            label: "Results",
            title: "Your route to ESG maturity",
            desc: "<p>Before you can build on ESG in your organisation, you need to know where your business is today. This report helps you assess your business’ ESG maturity and benchmarks you against others in your job function, industry and company size bracket.</p><br/><p>The ESG maturity score is based on your answers regarding how likely you are to prioritise important ESG matters as well as how proficient you deem your organisation to be in addressing them. The original sample for benchmarking is 800 business leaders working at businesses from £50million - £1 billion across 18 industries.</p>",
          },
        ],
      },
      includeIntoResult: true,
    },
    {
      name: "resultTabs",
      expression: {
        tabs: [
          {
            label: "ESG strategy and reporting",
          },
          {
            label: "Climate change",
          },
          {
            label: "Supply chain",
          },
          {
            label: "People and social impact",
          },
          {
            label: "ESG and tax",
          },
          {
            label: "Measurement",
          },
        ],
      },
      includeIntoResult: true,
    },
    {
      name: "maxSectionScores",
      expression: {
        maxScores: [
          {
            maxValue: 40,
          },
          {
            maxValue: 30,
          },
          {
            maxValue: 40,
          },
          {
            maxValue: 50,
          },
          {
            maxValue: 30,
          },
          {
            maxValue: 9,
          },
        ],
      },
      includeIntoResult: true,
    },
    {
      name: "sustainabilityResuts",
      expression: {
        levels: [
          {
            value:
              "The impact of ESG is having an increasing effect across all sectors on pricing and credit availability. This may not immediately restrict lender appetite but is likely to do so in the relatively near future as lenders prioritise ESG factors as part of their decisioning process. <br /><br />Considering a Sustainability Linked Loan at your next debt raise/refinance can help you achieve your overall financing needs.<br /><br />You should consider how a transition to sustainable finance for your business will help you to enhance your ESG commitments and achieve better pricing on loans or bonds.",
          },
          {
            value:
              "Lenders' risk appetite is increasingly influenced by borrowers' ESG credentials and the changes can be difficult to keep track of if you refinance infrequently.<br /><br />We know that 57% of lenders have an ESG lending strategy in place and the proportion is growing. The level of pricing benefit varies from one lender to another.<br /><br />It is an increasingly competitive market and it is becoming more important to have a well-developed story around your transition to net zero before engaging with lenders, as lenders are more and more concerned about greenwashing.",
          },
          {
            value:
              "Lenders' risk appetite is being influenced by borrowers' ESG credentials and the position is evolving. It can be difficult to keep track of if your refinancing only takes place infrequently. It is important that you stay as attractive as possible to your stakeholders and investors by continuing to develop your ESG credentials.<br /><br />We know that 57% of lenders have an ESG lending strategy in place. And striking the balance between ambitious and realistic ESG KPIs will help you continue to access the most favourable borrowing terms available.",
          },
        ],
      },
      includeIntoResult: true,
    },
  ],
  pages: [
    {
      name: "intro",
      elements: [
        {
          type: "html",
          name: "intro_text",
          html: "<div class='intro text-primary pt-4'><img src='img/GrantThornton.svg' class='mb-4' /><h1 class='font-headline'>Mapping<br />your route to <span class='text-teal block'>ESG maturity.</span></h1><p class='font-body mt-4 mb-4'>We’ve built a maturity index to help you understand where you can focus your ESG investment for impact.</p><p class='font-body mb-4'>Our maturity index consists of 42 questions and will take you approximately 30 minutes to complete or a little longer if you are working through it with an adviser.</p><p class='font-body mb-4'>On completion, you’ll receive a report which includes a maturity score based on your answers and shows how you compare to the relevant businesses in our 800-strong sample. Benchmarking is available to view by industry, job profile and size.</p><p class='font-body mb-4'>We may be in touch to discuss your results. You can continue the conversation about your ESG journey with one of our advisers or through a diagnostic workshop called an ESG room.</p><p class='font-body'>If you need any help to complete the questions, you can reach out to us at <a href='mailto:esgmaturityindex@uk.gt.com'>ESGMaturityIndex@uk.gt.com</a></p></div>",
        },
      ],
    },
    {
      title: "",
      description: "",
      questions: [
        {
          type: "text",
          name: "D01_Firstname",
          hideNumber: true,
          isRequired: true,
          title: "First name",
          "startWithNewLine": false,
          validators: [
            {
              type: "text",
              minLength: 3,
              maxLength: 30,
            },
          ],
          placeholder: "Please insert here",
        },
        {
          type: "text",
          name: "D02_Lastname",
          hideNumber: true,
          isRequired: true,
          title: "Last name",
          "startWithNewLine": false,
          validators: [
            {
              type: "text",
              minLength: 3,
              maxLength: 30,
            },
          ],
          placeholder: "Please insert here",
        },
        {
          type: "text",
          name: "D03_Email",
          hideNumber: true,
          isRequired: true,
          title: "Email address",
          validators: [
            { "type": "email" }
          ],
          placeholder: "Please insert here",
        },
        {
          type: "text",
          name: "D04_Company",
          hideNumber: true,
          isRequired: true,
          title: "What company do you work for?",
          validators: [
            {
              type: "text",
              minLength: 3,
              maxLength: 30,
            },
          ],
          placeholder: "Please insert here",
        },
        {
          type: "dropdown",
          name: "D05_Industry",
          hideNumber: true,
          isRequired: true,
          title:
            "Which sector or industry most applies to the company you work for?",
          choices: [
            "Facilities management",
            "Human capital (recruitment)",
            "Professional services",
            "Automotive",
            "Food and beverage",
            "Manufacturing and industrial",
            "Retail",
            "Financial services (asset management, banks, insurance)",
            "Medical devices",
            "Pharmaceuticals",
            "Primary/secondary care",
            "Social care",
            "Technology",
            "Media",
            "Telecommunications",
            "Transport",
            "Energy",
            "Real estate and construction",
          ],
          placeholder: "Select an option",
          renderAs: "dropdown-react",
        },
        {
          type: "dropdown",
          name: "D06_AnnualRevenueRange",
          hideNumber: true,
          isRequired: true,
          title: "What is your annual company turnover?",
          choices: [
            "£50 Million - £100 Million|£50 Million - £100 Million",
            "£101 Million - £500 Million|£101 Million - £500 Million",
            "£500 Million - £1 Billion|£500 Million - £1 Billion",
          ],
          renderAs: "dropdown-react",
        },
        {
          type: "dropdown",
          name: "D07_JobTitle",
          hideNumber: true,
          isRequired: true,
          hasNone: false,
          title: "What is your job function?",
          choices: [
            "I am a business leader (CEO, Managing Director, COO)",
            "I am a finance leader (CFO, Heads of Finance, Finance Director)",
            "I am a procurement leader (CPO, Procurement Director, Head of Procurement, Procurement Manager)",
            "I am a people leader (CHRO, Head of HR, HR Director)",
            "I am a sustainability leader (Chief Sustainability Officer, Head of Sustainability)",
          ],
          renderAs: "dropdown-react",
        },
      ],
    },
    {
      title: "Section 1 of 5: ESG strategy and reporting",
      description:
        "<p>From the starting point of identifying your priorities, your ESG strategy should manage the risks, create opportunities, and be effectively embedded into your organisation. Priorities should reflect incoming regulations, the reporting systems you use and your organisation's priorities.</p></p><p><span class='text-gold font-button'>Prioritisation</span> - How likely is your organisation to prioritise the following ESG matters?</p><p><span class='text-teal font-button'>Proficiency</span> - How proficient is your management at implementing these ESG matters?</p>",
      elements: [
        {
          type: "panel",
          name: "P01",
          title: "Question 1 - Data and reporting",
          description:
            "Understanding what framework, standards and regulations are relevant, so you can determine what data you need to capture, measure and when, to meet strategic objectives and reporting requirements.",
          elements: [
            {
              "type": "nouislider",
            "name": "Q01",
            "title": "Likelihood to prioritise",
            "rangeMin": 0,
            "rangeMax": 5,
            "step": 0.5,
            "defaultValue": 0.5,
            validators: [
            {
            type: "numeric",
            text: "Value must be greater than 0.5",
            minValue: 1
            }
            ],
            "pipsValues": [0, 20, 40, 60, 80, 100],
            "pipsText": [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
            }, {
                value: 5,
                text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
            }],
            "pipsDensity": 9,
            "hideNumber" : true,
            "isRequired" : true,
            },
            
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q02",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P02",
          title: "Question 2 - Assurance",
          description:
            "Ensuring ESG and non-financial information is relevant, complete, reliable, unbiased, and understandable. Providing credibility to public reports and other stakeholder engagement.",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q03",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q04",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P03",
          title:
            "Question 3 - Engagement with senior stakeholders within your organisation",
          description: "Effectively engage the board with the ESG agenda.",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q05",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q06",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P04",
          title: "Question 4 - Attractiveness to investors and lenders",
          description:
            "Calculating the cost/benefit of potential investment with a greater ESG focus versus the impact of doing nothing.",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q07",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q08",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
      ],
    },
    {
      title: "Section 2 of 5: Climate strategies",
      description:
        "<p>With the focus on transitioning to green, regulators and standard setters are aligning around common reporting standards as agents of change, and developing a climate strategy will play a vital role in future-proofing your organisation. Beyond meeting minimum requirements, there are opportunities available for organisations with a clear climate vision to take advantage of.</p><p><span class='text-gold font-button'>Prioritisation</span> - How likely is your organisation to prioritise the following ESG matters?</p><p><span class='text-teal font-button'>Proficiency</span> - How proficient is your management at implementing these ESG matters?</p>",
      elements: [
        {
          type: "panel",
          name: "P05",
          title:
            "Question 1 - Collating and measuring carbon footprint data (across all three Carbon Scopes<sup>*</sup>)",
          description:
            "<p>Understanding the carbon footprint of the organisation and its impact, designing and implementing a carbon reduction strategy.</p><p><span class='text-[1rem] text-[#999]'><sup>*</sup>Scope 1 are direct emissions from owned or controlled sources (eg, company vehicles, energy/heat generation at facilities).</span></p><p><span class='text-[1rem] text-[#999]'>Scope 2 are indirect emissions from the generation of purchased energy.</span></p><p><span class='text-[1rem] text-[#999]'>Scope 3 are all indirect emissions (not included in scope 2) that occur in the value chain of the reporting company, including both upstream and downstream emissions (eg, employee commuting, transportation and distribution, franchises and investments).</span></p>",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q09",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q10",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P06",
          title: "Question 2 - Carbon and energy reduction initiatives",
          description:
            "Is the business looking to prioritise carbon offsetting as a primary solution to emissions?",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q11",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q12",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P07",
          title: "Question 3 - Technology for data management",
          description:
            "Acquisition/investment/use of technologies that facilitate carbon reporting and carbon reduction progress.",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q13",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q14",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
      ],
    },
    {
      title: "Section 3 of 5: Sustainable, ethical and secure supply chains",
      description:
        "<p>Supply chain ethics, sustainability and security are a core risk consideration for organisations, particularly where aspects of the supply chain are based overseas. You need to ensure that all of the suppliers and partners involved in your organisation's supply chain are transparent, adhering to legislation, and that you have early visibility of potential cost increases.</p><p><span class='text-gold font-button'>Prioritisation</span> - How likely is your organisation to prioritise the following ESG matters?</p><p><span class='text-teal font-button'>Proficiency</span> - How proficient is your management at implementing these ESG matters?</p>",
      elements: [
        {
          type: "panel",
          name: "P08",
          title: "Question 1 - Supplier code of conduct and assessment",
          description:
            "Communication of the behaviours and employment standards expected of suppliers and their staff (eg diversity, equality and inclusion, working practices, modern slavery etc) and ablility to evaluate suppliers to operate to high ethical standards.",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q15",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q16",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P09",
          title: "Question 2 - Financial and operational risk assessment",
          description:
            "Determining if a third party is financially sound and access to materials is secure.",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q17",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q18",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P10",
          title: "Question 3 - Ethics",
          description:
            "Ensuring the business and its suppliers/wider stakeholders operate to high ethical standards.",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q19",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q20",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P11",
          title:
            "Question 4 - Optimise supply chain carbon footprint and efficiency",
          description:
            "Determining how carbon and cost can be taken out of the supply chain.",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q21",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q22",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
      ],
    },
    {
      title: "Section 4 of 5: People and social impact",
      description:
        "<p>When deciding where to work, considering whether an organisation is inclusive and purpose-led with strong values around ESG can be key in many people's decision-making process. Thinking strategically about factors such as inclusion and diversity and labour standards can help keep yourself as an employer of choice.</p><p><span class='text-gold font-button'>Prioritisation</span> - How likely is your organisation to prioritise the following ESG matters?</p><p><span class='text-teal font-button'>Proficiency</span> - How proficient is your management at implementing these ESG matters?</p>",
      elements: [
        {
          type: "panel",
          name: "P12",
          title: "Question 1 - Employee rights and employee compliance",
          description:
            "Establishing appropriate working conditions, fair/equal wages, overtime,  employee/human rights etc... and ensuring employees are compliant with organisation policies and code of conduct.",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q23",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q24",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P13",
          title: "Question 2 - Employment inclusion and diversity",
          description:
            "Ensuring inclusive and non-discriminatory policies (eg, diversity and inclusion, pay and recruitment).",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q25",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q26",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P14",
          title: "Question 3 - Employee wellbeing and work/life balance",
          description:
            "Managing employees’ physical and mental wellbeing and ensuring effective balance of work and career demands with those of their personal life (eg, preventative measure for burnout, limiting working overtime).",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q27",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q28",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P15",
          title: "Question 4 - Optimising productivity",
          description: "Helping employees to work smarter.",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q29",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q30",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P16",
          title: "Question 5 - Employee remuneration and benefits",
          description:
            "Meeting employee package demands  - building ESG focused benefits packages (ie, EV, bike to work, pension fund investments).",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q31",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q32",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
      ],
    },
    {
      title: "Section 5 of 5: ESG and tax",
      description:
        "<p>Tax policy may itself be a driver behind ESG-related business change in your organisation, with taxes on the use or production of  products such as non-recyclable plastics increasing globally. Alternatively, tax considerations may be part of your broader ESG agenda that can add or erode value depending on how proactive your organisation is in including tax early.</p><p><span class='text-gold font-button'>Prioritisation</span> - How likely is your organisation to prioritise the following ESG matters?</p><p><span class='text-teal font-button'>Proficiency</span> - How proficient is your management at implementing these ESG matters?</p>",
      elements: [
        {
          type: "panel",
          name: "P17",
          title: "Question 1 - Accounting for tax payments correctly globally",
          description:
            "Compliance and transparency with the relevant legal and regulatory requirements for tax.",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q33",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q34",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P18",
          title: "Question 2 - Tax incentives",
          description:
            "Optimising opportunities from corporate and employee-level tax incentives in the context of using these as “pay fors” for an expensive ESG-change journey.",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q35",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q36",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
        {
          type: "panel",
          name: "P19",
          title: "Question 3 - Fully costing future tax risks",
          description:
            "Forecasting future additional costs in your supply chain due to the changing tax environment driven by the ESG focus of governments – eg increasing taxes on carbon and other externalities.",
          elements: [
            {
              type: "nouislider",
              title: "Likelihood to prioritise",
              name: "Q37",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative -left-[15px] md:left-0] block leading-[1rem]'>very unlikely</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[29px] md:left-0] block leading-[1rem]'>very likely</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title: "Management ability to implement",
              name: "Q38",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='relative left-[8px] md:left-0] block leading-[1rem]'>not at all proficient</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem] -mt-[0.4rem]'>5</span><span class='relative -left-[16px] md:left-0] block leading-[1rem]'>highly</span><span class='relative -left-[27px] md:left-0] block leading-[1.2rem]'>proficient</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          showNumber: false,
        },
      ],
    },
    {
      title: "",
      description: "",
      questions: [
        {
          type: "checkbox",
          name: "Q39",
          title:
            "1 - What data and insights does your organisation currently collect?",
          colCount: 2,
          isRequired: true,
          "showNoneItem": true,
          choices: [
            "Carbon emissions",
            "Water usage",
            "Wasteage",
            "Equality pay reporting",
            "Organisational diversity, equity and inclusion",
            "Gender diversity at senior management level",
            "Employee wellbeing",
            "Culture",
            "Employee engagement",
          ],
          hideNumber: true,
        },
        {
          type: "radiogroup",
          name: "Q40",
          title:
            "2 - When thinking about your current borrowing or future borrowing requirements... [select the most relevant]",
          colCount: 1,
          isRequired: true,
          choices: [
            "We are aware of and will think of a sustainability linked loan (SLL) when we consider our next debt raise/re-finance",
            "We have a sustainability linked loan (SLL) in place or are in discussions with lenders regarding a SLL",
            "We have not considered our next financing decision yet or a SLL would not be appropriate for our business",
            "We do not believe our ESG strategy will have a material impact on our next financing.",
            "I do not know / am not involved in our approach to borrowing.",
          ],
          hideNumber: true,
        },
        {
          type: "panel",
          name: "P20",
          title:
            "3 - When considering your liquidity (borrowing) requirements, how much do you agree/disagree with the following statements?",
          description: "",
          elements: [
            {
              type: "nouislider",
              title:
                "We consider our current and/or future lender's ESG priorities when communicating with them.",
              name: "Q41",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='block leading-[1rem]'>disagree</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[13px] md:left-0 block leading-[1rem]'>agree</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title:
                "We understand how our ESG position influences the lender's risk appetite and the price we pay for borrowing.",
              name: "Q42",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='block leading-[1rem]'>disagree</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[13px] md:left-0 block leading-[1rem]'>agree</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
            {
              type: "nouislider",
              title:
                "We understand how our ESG credentials are measured and feed into our lender’s risk assessment and decisioning processes.",
              name: "Q43",
              rangeMin: 0,
              rangeMax: 5,
              step: 0.5,
              defaultValue: 0.5,
              validators: [
              {
              type: "numeric",
              text: "Value must be greater than 0.5",
              minValue: 1
              }
              ],
              pipsValues: [0, 20, 40, 60, 80, 100],
              pipsText: [{
                value: 1,
                text: "<span class='block leading-[1rem]'>1</span><span class='block leading-[1rem]'>disagree</span>"
              }, {
                  value: 5,
                  text: "<span class='block leading-[1rem]'>5</span><span class='relative -left-[13px] md:left-0 block leading-[1rem]'>agree</span>"
              }],
              pipsDensity: 9,
              hideNumber: true,
              isRequired: true,
            },
          ],
          state: "expanded",
          visibleIf:
            "{Q40}='We are aware of and will think of a sustainability linked loan (SLL) when we consider our next debt raise/re-finance' or {Q40}='We have a sustainability linked loan (SLL) in place or are in discussions with lenders regarding a SLL'",
          showNumber: false,
        },
      ],
    },
  ],
  cookieName: "esgmaturysess",
  firstPageIsStarted: true,
  widthMode: "responsive",
  showPageTitles: true,
  showPrevButton: true,
  showTitle: false,
  startSurveyText: "Start your journey",
};
