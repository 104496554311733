import React from "react";
import { ReactSVG } from "react-svg";

import grantThornton from "../../assets/GrantThornton.svg";

const Footer = () => {

    return (
    <>
        <footer className="p-4 bg-white shadow md:px-6 md:py-8 dark:bg-gray-900 mt-16">
            <div className="sm:flex sm:items-center sm:justify-between container">
                <a href="https://www.grantthornton.co.uk/" target="_blank" rel="noreferrer" className="flex items-center mb-4 sm:mb-0">
                    {/* <img src="https://flowbite.com/docs/images/logo.svg" className="h-8 mr-3" alt="Flowbite Logo" /> */}
                    <ReactSVG
                        className="mr-3"
                        src={grantThornton}
                      />
                </a>
                <ul className="flex flex-wrap items-center mb-4 pt-2 text-sm text-gray-500 sm:mb-0 dark:text-gray-400">
                    <li>
                        <a href="https://www.grantthornton.co.uk/privacy/" target="_blank" rel="noreferrer" className="mr-4 hover:underline md:mr-6 ">Privacy</a>
                    </li>
                    <li>
                        <a href=" https://www.grantthornton.co.uk/privacy/cookies/" target="_blank" rel="noreferrer" className="mr-4 hover:underline md:mr-6">Cookies</a>
                    </li>
                    <li>
                        <a href="https://www.grantthornton.co.uk/disclaimer/" target="_blank" rel="noreferrer" className="mr-4 hover:underline md:mr-6 ">Disclaimer</a>
                    </li>
                    <li>
                        <a href="https://www.grantthornton.co.uk/terms-and-conditions/" target="_blank" rel="noreferrer" className="hover:underline">Terms and conditions</a>
                    </li>
                </ul>
            </div>
        </footer>
    </>
  );
};

export { Footer };