import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Firebase configuration TEST config
// const firebaseConfig = {
//   apiKey: "AIzaSyAR_W4Svj65MjEY2NwNIfI53aC-42umCog",
//   authDomain: "gt-survey-tool-2022.firebaseapp.com",
//   projectId: "gt-survey-tool-2022",
//   storageBucket: "gt-survey-tool-2022.appspot.com",
//   messagingSenderId: "987624070333",
//   appId: "1:987624070333:web:8a450d923631fc80116bd4",
// };
//Staging config
const firebaseConfig = {
  apiKey: "AIzaSyDlC3oC8PcLuL6daIqyLMJbaGKRXYPKjik",
  authDomain: "esg-staging-2023.firebaseapp.com",
  projectId: "esg-staging-2023",
  storageBucket: "esg-staging-2023.appspot.com",
  messagingSenderId: "849821097171",
  appId: "1:849821097171:web:b7b73cf7b77408b3344826"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firestore
export const db = getFirestore(firebaseApp);
