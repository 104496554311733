import React from "react";
import { SurveyQuestionDropdown } from "survey-react-ui";

// Set up a custom component that will render React Select
class CustomSelect extends SurveyQuestionDropdown {
  constructor(props) {
    super(props);
    this.selectToggle = this.selectToggle.bind(this);
    this.pickOption = this.pickOption.bind(this);
    this.state = {
      isOpen: false,
      isSelected: false,
      selectedText: this.question.placeholder,
      // isCompleted: false,
    };
    // this.onCompleteComponent = this.onCompleteComponent.bind(this);
  }

  onCompleteComponent() {
    this.setState({ isCompleted: true });
  }

  selectToggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  pickOption(e) {
    // console.log("e target", e.target.getAttribute("data-value"));
    // console.log("e target Text", e.target.textContent);
    this.setState({
      isOpen: !this.state.isOpen,
      isSelected: !this.state.isSelected,
    });
    this.setState({ selectedText: e.target.textContent });
    this.setValueCore(e.target.getAttribute("data-value"));
  }

  renderElement() {
    // If the question is non-editable, render a stylized div
    if (this.isDisplayMode) {
      return (
        <div
          id={this.question.inputId}
          className={this.question.getControlClass()}
          disabled
        >
          {this.question.displayValue || this.question.placeholder}
        </div>
      );
    }
    // const { isOpen, isSelected } = this.state;
    // console.log("this.options", this.options);
    // Otherwise, render the React Select component
    return (
      <div
        id={this.question.inputId}
        className={this.question.getControlClass()}
      >
        <span className="form-label" id="select-label-1"></span>
        <div className={`select ${this.state.isOpen ? "open" : ""}`}>
          <button
            className="select-toggle"
            aria-haspopup="listbox"
            aria-labelledby="select-label-1"
            onClick={this.selectToggle}
          >
            <span className="select-toggle-text">
              {this.state.selectedText}
            </span>
            <span className="select-toggle-icon">
              <i
                className="icon icon-chevron-small-down"
                aria-hidden="true"
              ></i>
            </span>
          </button>
          <ul
            className="nav select-menu"
            tabIndex="-1"
            role="listbox"
            aria-labelledby="select-label-1"
          >
            {this.question.visibleChoices.map((item, i) => (
              <li
                role="option"
                data-value={item.text ? item.text : item.value}
                key={"item" + i}
                className={`${
                  this.state.selectedText === item.value ? "selected" : ""
                }`}
                // eslint-disable-next-line
                aria-selected={`${
                  this.state.selectedText === item.value ? "true" : ""
                }`}
                onClick={this.pickOption}
              >
                {item.value}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default CustomSelect;
