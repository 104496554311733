import React, { useState, useEffect, useCallback } from "react";
// import ReactDOM from "react-dom";

// Marketo Custom Hook
// import useMarketo from "../../hooks/useMarketo.js";

// Firebase Firestore DB
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase.config";

// SurveyJS
import { RendererFactory } from "survey-core";
import { ReactQuestionFactory } from "survey-react-ui";
import { StylesManager, Model, Serializer } from "survey-core";
import { Survey } from "survey-react-ui";
import * as SurveyCore from "survey-core";
import * as widgets from "surveyjs-widgets";

// Import Survey CSS & Custom CSS Classes
import "survey-core/defaultV2.min.css";
import "nouislider/distribute/nouislider.min.css";
import { surveyCssClasses } from "./surveyCssClasses";

// Add showdown to enable HTML in Page Description
import { Converter } from "showdown";

// Data
import { json } from "./surveyData";

// Components
import CustomSelect from "./customSelect.js";
import { ProgressBar } from "./progressbar";
import { SurveyHeader } from "./surveyHeader";
import { ResultsPage } from "./resutsPage";

import { Send2Marketo } from "./send2Marketo";
import { Send2Firestore } from "./send2Firestore";

// No UI Slider init
widgets.nouislider(SurveyCore);

// Default SurveyJS theme
StylesManager.applyTheme("defaultV2");

Serializer.findProperty("question", "minWidth").defaultValue = "unset";

// Import Top Sections Content
const sections = json.calculatedValues[0].expression;
// Import Label for the result opage tabs.
const pageLabels = json.calculatedValues[1].expression;
// Import Max possible score for pages to show results as percentages.
const pageMaxScores = json.calculatedValues[2].expression;
// Get sustainability possible scoring content.
const sustainabiityScoring = json.calculatedValues[3].expression;

// export interface SurveyProps {
//   model: any;
// }

// Register the CustomSelect component as a renderer under a custom name "sv-dropdown-react"
ReactQuestionFactory.Instance.registerQuestion("sv-dropdown-react", (props) => {
  return React.createElement(CustomSelect, props);
});
// Register "sv-dropdown-react" as a renderer for questions whose `type` is "dropdown" and `renderAs` property is "dropdown-react"
RendererFactory.Instance.registerRenderer(
  "dropdown",
  "dropdown-react",
  "sv-dropdown-react"
);

const ESGSurvey = (props) => {
  // To group Pages in Sections
  const [currentIndex, setCurrentIndex] = useState(0);
  // Survey Competed State
  const [isCompleted, setIsCompleted] = useState(false);

  // Results States (some are no longer needed.)
  const [resultsData, setResultsData] = useState();
  const [pairedResults, setPairedResults] = useState([]);
  const [totalPairedScore, setTotalPairedScore] = useState([]);
  const [augmentedResults, setAugmentedResults] = useState([]);
  const [totalPriority, setTotalPriority] = useState([]);
  const [totalProficency, setTotalProficency] = useState([]);
  const [totalResults, setTotalResults] = useState([]);
  const [measurementResults, setMeasurementResults] = useState([]);
  const [sustainableScore, setSustainableScore] = useState([]);
  const [sustainableResult, setSustainableResult] = useState([]);
  const [sustainableFinance, setSustainableFinance] = useState([]);
  const [panelTitles, setPanelTitles] = useState([]);

  // Set a ref to our Firestore collection.
  //staging
  const colletionRef = collection(db, "esg_staging");
  //test
  // const colletionRef = collection(db, "esgps");
  // Set a loading State for our Firestore queries.
  const [loading, setLoading] = useState(false);
  // States to handle our comparison data.
  const [industry, setIndustry] = useState();
  const [jobFunc, setJobFunc] = useState();
  const [revenue, setRevenue] = useState();
  // States to hold our comparison data.
  const [getByIndustry, setGetByIndustry] = useState();
  const [getByJobFunc, setGetByJobFunc] = useState();
  const [getByRevenue, setGetByRevenue] = useState();

  // Make sure we run the queries just one.
  // const isQueried = useRef(false);

  // Calculate average values and round to the nearest half (1, 1.5, 2, 2.5, etc.)
  const caclAvgerage = (data) => {
    const newData = Array.from(
      data.reduce(
        (acc, obj) =>
          Object.keys(obj).reduce(
            (acc, key) =>
              typeof obj[key] == "number"
                ? acc.set(key, (acc.get(key) || []).concat(obj[key]))
                : acc,
            acc
          ),
        new Map()
      )
    ).reduce(
      (acc, [name, values]) =>
        Object.assign(acc, {
          [name]:
            Math.round((values.reduce((a, b) => a + b) / values.length) * 2) /
            2,
        }),
      {}
    );
    return newData;
  };
  
  // Get by Industry
  useEffect(() => {
    // console.log("industry", industry);
    if (industry === undefined) return;
    const getIndustry = async () => {
      // console.log("industry inside useEffect", industry);
      // if (isQueried.current) return;
      // isQueried.current = true;

      setLoading(true);

      const q = query(colletionRef, where("D05_Industry", "==", industry));

      const querySnapshot = await getDocs(q);

      const items = [];

      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      //console.log("getIndustry items", items);
      const averageIndustry = caclAvgerage(items);
      //console.log("averageIndustry", averageIndustry);
      setGetByIndustry(averageIndustry);
      setLoading(false);
    };

    try {
      getIndustry();
    } catch (error) {
      console.error(error);
    }

    // eslint-disable-next-line
  }, [industry]);

  useEffect(() => {
    // console.log("jobFunc", jobFunc);
    if (jobFunc === undefined) return;
    const getJobFunc = async () => {
      // console.log("jobFunc inside useEffect", jobFunc);
      // if (isQueried.current) return;
      // isQueried.current = true;

      setLoading(true);

      const q = query(colletionRef, where("D07_JobTitle", "==", jobFunc));

      const querySnapshot = await getDocs(q);

      const items = [];

      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      //console.log("getJobFunc items", items);
      const averageJobFunc = caclAvgerage(items);
      //console.log("averageJobFunc", averageJobFunc);
      setGetByJobFunc(averageJobFunc);
      setLoading(false);
    };

    try {
      getJobFunc();
    } catch (error) {
      console.error(error);
    }

    // eslint-disable-next-line
  }, [jobFunc]);

  useEffect(() => {
    // console.log("revenue", revenue);
    if (revenue === undefined) return;
    const getRevenue = async () => {
      // console.log("revenue inside useEffect", revenue);
      // if (isQueried.current) return;
      // isQueried.current = true;

      setLoading(true);

      const q = query(
        colletionRef,
        where("D06_AnnualRevenueRange", "==", revenue)
      );

      const querySnapshot = await getDocs(q);

      const items = [];

      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      //console.log("getRevenue items", items);
      const averageRevenue = caclAvgerage(items);
      //console.log("averageRevenue", averageRevenue);
      setGetByRevenue(averageRevenue);
      setLoading(false);
    };

    try {
      getRevenue();
    } catch (error) {
      console.error(error);
    }

    // eslint-disable-next-line
  }, [revenue]);

  const survey = new Model(json);
  survey.focusFirstQuestionAutomatic = false;

  // On Survey Complete.
  const finalResults = useCallback((sender) => {
    // Set section Index.
    setCurrentIndex(8);
    const results = sender;
    // Get the options for the Sustainability Radio question Q40
    const sustainabilityOptions = json.pages[7].questions[1].choices;

    // Get the results.
    const plainData = results.getPlainData();
    // Get all questions (not needed. Debug only.)
    const allQuestions = results.getAllQuestions();
    // Get all answers.
    const plainValues = plainData.map((t) => t.value);

    const measurementResults = plainData.reduce(function (sum, question) {
      // console.log("reduce question", question);
      if (question.name === "Q39") {
        sum.push(question.value.length);
      } else if (
        question.name === "Q40" ||
        question.name === "Q41" ||
        question.name === "Q42" ||
        question.name === "Q43"
      ) {
        sum.push(question.value);
      }
      return sum;
    }, []);

    // console.log("plainData", plainData);
    // console.log("plainValues", plainValues);
    // console.log("allQuestions", allQuestions);
    // console.log("results", results);

    const sustainableScore = measurementResults
      .slice(-3)
      .reduce((acc, val) => acc + val);

    setIsCompleted(true);
    // Plain data to get Demographics (page 1)
    setResultsData(plainData);
    // Results for the Measurement oage (page 7).
    setMeasurementResults(measurementResults);
    // Sustainable finance calcultaion
    // See https://docs.google.com/document/d/1-qu70jqYe_ylzLgy6vVbwpL_nkV85-mtya5P4pCS_DA/edit?usp=sharing
    let sustainableFinance;
    let sustainableResult;
    if (measurementResults[1] === sustainabilityOptions[4]) {
      sustainableFinance = 0;
    } else if (
      measurementResults[1] === sustainabilityOptions[3] ||
      measurementResults[1] === sustainabilityOptions[2]
    ) {
      sustainableFinance = 1; // "Emerging"
      sustainableResult = sustainabiityScoring.levels[0].value;
    } else if (measurementResults[1] === sustainabilityOptions[0]) {
      sustainableFinance = 1; // "Developing"
      sustainableResult = sustainabiityScoring.levels[2].value;
    } else {
      if (sustainableScore >= 12) {
        sustainableFinance = 3; // "Advanced"
        sustainableResult = sustainabiityScoring.levels[2].value;
      } else {
        sustainableFinance = 2; // "Developing"
        sustainableResult = sustainabiityScoring.levels[1].value;
      }
    }
    // Grade for the Sustainable Questions Q40 Q41 Q42 Q43
    setSustainableFinance(sustainableFinance);
    // Resulting Content for the Sustainable Grade
    setSustainableResult(sustainableResult);
    // Results for the Sustainable Scroe
    setSustainableScore(sustainableScore);
  }, []);

  // Creating an Object to old all of the paired question values (1 to 38).
  let resultsPerPage = {};
  let panelsTitlePerPage = {};
  const onCurrentPageChanged = (sender, option) => {
    window.scrollTo(0, 0);
    // Set currentIndex state.
    setCurrentIndex(survey.currentPageNo + 1);

    // Query DB for same Industry respondents.
    if (option.oldCurrentPage.num === 1) {
      // console.log(
      //   "Industry oldCurrentPage",
      //   option.oldCurrentPage.questions[4].value
      // );
      setIndustry(option.oldCurrentPage.questions[4].value);
    }
    // Query DB for same Annual Revenue respondents.
    if (option.oldCurrentPage.num === 2) {
      const revenueQ = sender.getQuestionByName("D06_AnnualRevenueRange");
      // console.log("AnnualRevenue revenueQ", revenueQ.value);
      setRevenue(revenueQ.value);
    }
    // Query DB for same Job Function respondents.
    if (option.oldCurrentPage.num === 3) {
      const jobQ = sender.getQuestionByName("D07_JobTitle");
      // console.log("AnnualRevenue revenueQ", jobQ.value);
      setJobFunc(jobQ.value);
    }

    // Pick just the pages with paired sliders for prioritization/proficency.
    if (option.oldCurrentPage.num > 1 && option.oldCurrentPage.num <= 6) {
      let num = option.oldCurrentPage.num;

      // console.log("option.oldCurrentPage", option.oldCurrentPage);
      // Adding the question values for each page.
      resultsPerPage["Page" + num] = [];

      // Add values for each page.
      option.oldCurrentPage.questions.forEach((q) => {
        // console.log("question", q.getType());

        if (q.getType() === "nouislider") {
          resultsPerPage["Page" + num].push(q.value);
        }
      });

      // Adding the panel titles array for each page.
      panelsTitlePerPage["Page" + num] = [];

      // Add panel titles for each page.
      option.oldCurrentPage.elements.forEach((panel) => {
        panelsTitlePerPage["Page" + num].push(panel.title);
      });
    }

    //
    // If I am on the second to last page.
    if (option.oldCurrentPage.num === 6) {
      /// Flattening the resultsPerPage object to an array of arrays.
      const result = Object.keys(resultsPerPage).map((key) => {
        return resultsPerPage[key];
      });

      // Augmenting the second question of each panel by * 1.5. EDIT revert to 1
      const augmentedResults = result.map(function (page) {
        return page.map(function (question, index) {
          // return question * (index % 2 === 0 ? 1 : 1.5);
          return question * (index % 2 === 0 ? 1 : 1);
        });
      });

      // Getting only priority values to calculate their total
      const getPriorityValues = augmentedResults.map(function (page) {
        return page.map(function (question, index) {
          return question * (index % 2 === 0 ? 1 : 0);
        });
      });

      // Total priority
      const totalPriorityPerPage = getPriorityValues.map(function (page) {
        return page.reduce((accumulator, value) => {
          return accumulator + value;
        }, 0);
      });

      // Getting only Proficency values to calculate their total
      const getProficencyValues = augmentedResults.map(function (page) {
        return page.map(function (question, index) {
          return question * (index % 2 === 0 ? 0 : 1);
        });
      });

      // Total priority
      const totalProficencyPerPage = getProficencyValues.map(function (page) {
        return page.reduce((accumulator, value) => {
          return accumulator + value;
        }, 0);
      });

      const totalResultsPerPage = augmentedResults.map(function (page) {
        return page.reduce((accumulator, value) => {
          return accumulator + value;
        }, 0);
      });

      // Sum each pair or results (by panel)
      const pairedResults = augmentedResults.map(function (page, index) {
        const resArr = [];
        let n = page.length;
        for (let i = 0; i < n; i += 2) {
          resArr.push(page[i] + (page[i + 1] || 0));
        }
        return resArr;
      });

      const panelTitles = Object.keys(panelsTitlePerPage).map((key) => {
        return panelsTitlePerPage[key];
      });

      // console.log("result", result);
      // console.log("augmentedResults", augmentedResults);
      // console.log("totalResultsPerPage", totalResultsPerPage);
      // console.log("pairedResults", pairedResults);
      // console.log("panelsTitlePerPage", panelsTitlePerPage);
      // console.log("panelTitles", panelTitles);
      // console.log("getProficencyValues", getProficencyValues);
      // console.log("getPriorityValues", getPriorityValues);
      // console.log("totalProficencyPerPage", totalProficencyPerPage);
      // console.log("totalPriorityPerPage", totalPriorityPerPage);

      // Calculate the Total Paired Score.
      const pairedScore = pairedResults.map(function (page) {
        return page.reduce((partialSum, a) => partialSum + a, 0);
      });
      const totalPairedScore = pairedScore.reduce(
        (partialSum, a) => partialSum + a,
        0
      );
      // Total ESG Maturity Score.
      setTotalPairedScore(totalPairedScore);
      // Sum of each pair of sliders (pages 2 to 6).
      setPairedResults(pairedResults);
      // Paneels titles
      setPanelTitles(panelTitles);
      // Weighted slider values (pages 2 to 6).
      setAugmentedResults(augmentedResults);
      setTotalPriority(totalPriorityPerPage);
      setTotalProficency(totalProficencyPerPage);
      // Total of each page (2 to 6).
      setTotalResults(totalResultsPerPage);
    }
  };

  const onStarted = (sender, option) => {
    setCurrentIndex(1);
  };

  const converter = new Converter();
  survey.onTextMarkdown.add(function (survey, options) {
    // Convert the mardown text to html.
    var str = converter.makeHtml(options.text);
    // Remove root paragraphs <p></p>.
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    // Set html.
    options.html = str;
  });

  const surveyJS = React.useMemo(
    () => (
      <>
        <Survey
          css={surveyCssClasses}
          model={survey}
          onCurrentPageChanged={onCurrentPageChanged}
          onStarted={onStarted}
          onComplete={finalResults}
        />
      </>
    ),
    // eslint-disable-next-line
    []
  );
  return (
    <>
      <ProgressBar sections={sections.sections} currentIndex={currentIndex} />
      <div className={`bg-white pl-4 pr-4 pb-20 pt-1 sm:pl-10 sm:pr-10 sm:pb-10 md:pl-20 md:pr-20 md:pb-20 rounded-t-xl rounded-bl-xl rounded-br-custom md:mr-16 md:ml-16 ${currentIndex === 0 ? "lg:max-w-[48%] first-page" : ""}`}>
        <SurveyHeader
          sections={sections.sections}
          currentIndex={currentIndex}
        />
        {!isCompleted && surveyJS}
        {isCompleted && (
          <ResultsPage
            sections={sections.sections}
            pageLabels={pageLabels.tabs}
            pageMaxScores={pageMaxScores.maxScores}
            resultsData={resultsData}
            sustainableScore={sustainableScore}
            sustainableResult={sustainableResult}
            sustainableFinance={sustainableFinance}
            totalPairedScore={totalPairedScore}
            pairedResults={pairedResults}
            totalResults={totalResults}
            panelTitles={panelTitles}
            measurementResults={measurementResults}
            isCompleted={isCompleted}
            totalPriority={totalPriority}
            totalProficency={totalProficency}
            compareByIndustry={getByIndustry}
            compareByJobFunc={getByJobFunc}
            compareByRevenue={getByRevenue}
          />
        )}
        {isCompleted && (
          <>
            <Send2Marketo
              resultsData={resultsData}
              totalResults={totalResults}
              pageLabels={pageLabels.tabs}
              totalPriority={totalPriority}
              totalProficency={totalProficency}
              measurementResults={measurementResults}
              sustainableFinance={sustainableFinance}
              sustainableResult={sustainableResult}
              totalPairedScore={totalPairedScore}
              //totalPriority={totalPriority}
              panelTitles={panelTitles}
            />
            <Send2Firestore
              resultsData={resultsData}
              pairedResults={pairedResults}
              totalResults={totalResults}
              augmentedResults={augmentedResults}
              measurementResults={measurementResults}
              sustainableFinance={sustainableFinance}
              sustainableResult={sustainableResult}
              totalPairedScore={totalPairedScore}
            />
          </>
        )}
      </div>
    </>
  );
};
export default ESGSurvey;
