import React, { useEffect, useRef } from "react";

// Firebase Firestore DB
import { updateDoc, serverTimestamp, collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase.config";

type Props = {
  resultsData: any;
  augmentedResults: any;
  pairedResults: any;
  totalResults: any;
  measurementResults: any;
  sustainableFinance: any;
  totalPairedScore: any;
};

//Test database
// const addRecord = async (firestoreData: any) => {
//   // e.preventDefault();
//   console.log("firestoreData", firestoreData);
//   try {
//     const docRef = await addDoc(collection(db, "esgps"), {
//       esgps: firestoreData,
//     });
//     console.log("Document written with ID: ", docRef.id);
//   } catch (e) {
//     console.error("Error adding document: ", e);
//   }
// };
// Staging database
const addRecord = async (firestoreData: any) => {
  // e.preventDefault();
  //console.log("firestoreData", firestoreData);
  try {
    const docRef = await addDoc(collection(db, "esg_staging"), {
      esg_staging: firestoreData,
      
    });
    // Update the timestamp field with the value from the server
    const updateTimestamp = await updateDoc(docRef, {
    timestamp: serverTimestamp()
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};


const Send2Firestore = (props: Props) => {
  const dataFetchedRef = useRef(false);
  // Plain data to get Demographics (page 1)
  const resultsData = props.resultsData;
  // Weighted slider values (pages 2 to 6).
  const augmentedResults = props.augmentedResults;
  // Sum of each pair of sliders (pages 2 to 6).
  const pairedResults = props.pairedResults;
  // Total of each page (2 to 6).
  const totalResults = props.totalResults;
  // Results for the Measurement oage (page 7).
  const measurementResults = props.measurementResults;
  // Sustainable Fianance Grade.
  const sustainableFinance = props.sustainableFinance;
  // Total MaturityScore
  const totalPairedScore = props.totalPairedScore;
  // Firestore Playload State
  const firestoreData = {
    D01_Firstname: resultsData[1].value,
    D02_Lastname: resultsData[2].value,
    D03_Email: resultsData[3].value,
    D04_Company: resultsData[4].value,
    D05_Industry: resultsData[5].value,
    D06_AnnualRevenueRange: resultsData[6].value,
    D07_JobTitle: resultsData[7].value,
    P01: pairedResults[0][0],
    P02: pairedResults[0][1],
    P03: pairedResults[0][2],
    P04: pairedResults[0][3],
    P05: pairedResults[1][0],
    P06: pairedResults[1][1],
    P07: pairedResults[1][2],
    P08: pairedResults[2][0],
    P09: pairedResults[2][1],
    P10: pairedResults[2][2],
    P11: pairedResults[2][3],
    P12: pairedResults[3][0],
    P13: pairedResults[3][1],
    P14: pairedResults[3][2],
    P15: pairedResults[3][3],
    P16: pairedResults[3][4],
    P17: pairedResults[4][0],
    P18: pairedResults[4][1],
    P19: pairedResults[4][2],
    Q01: augmentedResults[0][0],
    Q02: augmentedResults[0][1],
    Q03: augmentedResults[0][2],
    Q04: augmentedResults[0][3],
    Q05: augmentedResults[0][4],
    Q06: augmentedResults[0][5],
    Q07: augmentedResults[0][6],
    Q08: augmentedResults[0][7],
    Q09: augmentedResults[1][0],
    Q10: augmentedResults[1][1],
    Q11: augmentedResults[1][2],
    Q12: augmentedResults[1][3],
    Q13: augmentedResults[1][4],
    Q14: augmentedResults[1][5],
    Q15: augmentedResults[2][0],
    Q16: augmentedResults[2][1],
    Q17: augmentedResults[2][2],
    Q18: augmentedResults[2][3],
    Q19: augmentedResults[2][4],
    Q20: augmentedResults[2][5],
    Q21: augmentedResults[2][6],
    Q22: augmentedResults[2][7],
    Q23: augmentedResults[3][0],
    Q24: augmentedResults[3][1],
    Q25: augmentedResults[3][2],
    Q26: augmentedResults[3][3],
    Q27: augmentedResults[3][4],
    Q28: augmentedResults[3][5],
    Q29: augmentedResults[3][6],
    Q30: augmentedResults[3][7],
    Q31: augmentedResults[3][8],
    Q32: augmentedResults[3][9],
    Q33: augmentedResults[4][0],
    Q34: augmentedResults[4][1],
    Q35: augmentedResults[4][2],
    Q36: augmentedResults[4][3],
    Q37: augmentedResults[4][4],
    Q38: augmentedResults[4][5],
    R01_ESGStrategy: totalResults[0],
    R02_ESGClimateChangeStrategies: totalResults[1],
    R03_ESGSusEthSecSupplyChain: totalResults[2],
    R04_ESGPeopleandSocialImpact: totalResults[3],
    R05_ESGTax: totalResults[4],
    R06_ESGMaturityScore: totalPairedScore,
    R07_ESGMeasurement: measurementResults[0],
    R08_ESGSustainableFinancing: sustainableFinance,
  };

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    addRecord(firestoreData);
  }, []);
  // addRecord(firestoreData);
};

export { Send2Firestore };
