import React from "react";
import scottWilson from '../../assets/scottwilson.png'; 
import emailSVG from "../../assets/email.svg";
import phoneSVG from "../../assets/phone.svg";
import { ReactSVG } from "react-svg";

const Experts = () => {

    return (
    <>
        <div className="flex md:flex-row flex-col mt-8">
            <img className="m-auto md:m-0 w-40 h-40 object-cover rounded-full shadow-lg border border-gray mt-4" src={scottWilson} alt="Paul Wilson" />
            <div className="bg-white  px-10 max-w-xs m-auto text-center md:m-0 md:text-left">
                <p className="font-body text-primary mt-4">Scott Wilson </p>
                <p className="text-body mt-1">Head of ESG and Sustainability </p>
                <div className="inline-flex mt-4">
                  <div className="flex flex-row">
                    <ReactSVG
                      src={phoneSVG}
                    />
                    <a className="ml-2" href="tel:+44 (0)20 7728 3068">+44 (0)20 7728 3068</a>
                  </div>
                </div>
                <div className='inline-flex mt-4'>
                  <div className="flex flex-row">
                    <ReactSVG
                      src={emailSVG}
                    />
                    <a className="ml-2" href="mailto:scott.wilson@uk.gt.com">scott.wilson@uk.gt.com</a>
                  </div>
                </div>
            </div>
        </div>

    </>
  );
};

export { Experts };