import React from "react";
import "./App.css";
import { ReactSVG } from "react-svg";
import Div100vh from "react-div-100vh";
import TagManager from "react-gtm-module";

import ESGSurvey from "./components/survey/surveyConfig";
import { Footer } from "./components/survey/footer";

import esgpsBg from "./assets/gt-topography.svg";

const tagManagerArgs = {
    gtmId: 'G-8GH00HPN9H'
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <Div100vh>
      <div className="App">
        <div className="esgps w-full ">
          <div className="esgps-app mx-auto">
            <ESGSurvey />
          </div>
          <div className="esgps-app mx-auto">
            <Footer />
          </div>
          <ReactSVG className="esgps-bg" src={esgpsBg} />
        </div>
      </div>
    </Div100vh>
  );
}

export default App;
