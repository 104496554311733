import React from "react";
import circle from '../../assets/circle.png'; 
type Props = {
  num: number;
  color: string;
};

const ScoreProgress = (props: Props) => {
  const num = props.num;
  const color = props.color;

  // Color should be replae by the matching value in tailwindd config, Not havving enought time to solve this now.
  const barColor = color ? color : "#4f2d7f";
  const styles = {
    progress: {
      width: num + "%",
      backgroundColor: barColor,
    },
    pin: {
      color: "#000000",
    },
  } as const;

  return (
    <div className="progressBar progressBar--chart bg-white mb-1">
      <div className="progress w-full relative flex">
        <div className={`h-6 bg-teal rounded-r-[20px]`} style={styles.progress}>
          <img className="right-[4px] relative h-[17px] w-[17px] top-[3.5px] float-right" src={circle} />
        </div>
        <div className="pin ml-2" style={styles.pin}>
          {num}%
        </div>
      </div>
    </div>
  );
};

export { ScoreProgress };
