// Marketo Custom Hook
import useMarketo from "../../hooks/useMarketo.js";

type Props = {
  resultsData: any;
  totalResults: any;
  totalPriority: any;
  totalProficency: any;
  pageLabels: any;
  measurementResults: any;
  sustainableFinance: any;
  totalPairedScore: any;
};

const Send2Marketo = (props: Props) => {
  // Plain data to get Demographics (page 1)
  const resultsData = props.resultsData;
  // Total of each page (2 to 6).
  const totalResults = props.totalResults;
  const totalPriority = props.totalPriority;
  const totalProficency = props.totalProficency;
  const pageLabels = props.pageLabels;
  // Results for the Measurement page (page 7).
  const measurementResults = props.measurementResults;
  // Sustainable Fianance Grade.
  const sustainableFinance = props.sustainableFinance;
  // Total MaturityScore
  const totalPairedScore = props.totalPairedScore;
  // Test data. Reolace with data from Props.

  // Calcultate Priority Area
  let priorityArray = [];
  for (let i = 0; i < totalPriority.length; i++) {
    priorityArray.push(totalPriority[i] - totalProficency[i]);
  }

  // Get the highest priority value.
  const maxpriorityArray = Math.max(...priorityArray);
  // Get the position of the highest priority value inside the array..
  const sectionIndex = priorityArray.indexOf(maxpriorityArray);
  // Get the page matching the highest priority value.
  const PriorityScore = pageLabels[sectionIndex].label;
  //console.log(PriorityScore)
  const marketoData = {
    //sandbox environment
    // FirstName: resultsData[1].value,
    // LastName: resultsData[2].value,
    // Email: resultsData[3].value,
    // Company: resultsData[4].value,
    // industryDiagnosticTool: resultsData[5].value,
    // Title: resultsData[7].value,
    // annualRevenueRange: resultsData[6].value,
    // eSGStrategyDiagnosticTool: totalResults[0],
    // climateChangeStrategiesDiagnosticTool: totalResults[1],
    // sustainableEthicalandSecureSupplyChainDiagnosticTool: totalResults[2],
    // peopleandSocialImpactDiagnosticTool: totalResults[3],
    // eSGTaxDiagnosticTool: totalResults[4],
    // overallMaturityScoreDiagnosticTool: totalPairedScore,
    // measurement2DiagnosticTool: measurementResults[0],
    // sustainableFinancing2DiagnosticTool: sustainableFinance,
    // utmcontent: "",
    // utmcampaign: "diagnostic tool",
    // utmmedium: "email",
    // utmsource: "personal-outreach",
    // utmterm: "",
    //live environment
    FirstName: resultsData[1].value,
    LastName: resultsData[2].value,
    Email: resultsData[3].value,
    Company: resultsData[4].value,
    companyGTIndustrySector: resultsData[5].value,
    //Title: resultsData[7].value,
    eSGJobTitle: resultsData[7].value,
    annualRevenueRange: resultsData[6].value,
    eSGStrategyDiagnosticTool: totalResults[0],
    climateChangeStrategiesDiagnosticTool: totalResults[1],
    sustainableEthicalandSecureSupplyChainDiagnosticTool: totalResults[2],
    peopleandSocialImpactDiagnosticTool: totalResults[3],
    eSGTaxDiagnosticTool: totalResults[4],
    overallMaturityScore: totalPairedScore,
    measurmentDiagnosticToolScore: measurementResults[0],
    sustainableFinancingDiagnosticToolScore: sustainableFinance,
    areaofFocusDiagnosticTool: PriorityScore,
    utmcontent: "",
    utmcampaign: "diagnostic tool",
    utmmedium: "email",
    utmsource: "personal-outreach",
    utmterm: "",
  };

  useMarketo({
    data: marketoData,
    // baseUrl: "//415-MUH-279.mktoweb.com",
    // munchkinId: "415-MUH-279",
    // formId: "1002",
    baseUrl: "//445-UIT-144.mktoweb.com",
    munchkinId: "445-UIT-144",
    formId: "3605",
    callback: function (form: any) {
      form.addHiddenFields(marketoData);
      form.onSuccess(function (e: any) {
        //console.log("e onSuccess", e);
        //console.log("onSuccess");
        return false;
      });
      form.onValidate(function (e: any) {
        //console.log("e onValidate", e);
        //console.log("onValidate");
      });
      form.onSubmit(function (e: any) {
        //console.log("e onSubmit", e);
        //console.log("onSubmit");
      });
    },
  });
  return null;
};

export { Send2Marketo };
