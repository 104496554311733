import React from "react";

type Props = {
  sections: [
    {
      label: string;
      title: string;
      desc: string;
    }
  ];
  currentIndex: number;
};

let sectionIndex: number;

const SurveyHeader = (props: Props) => {
  // Grouping page indexes into sections.
  if (props.currentIndex === 0 || props.currentIndex === 1) {
    sectionIndex = props.currentIndex;
  } else if (props.currentIndex >= 2 && props.currentIndex <= 6) {
    sectionIndex = 2;
  } else if (props.currentIndex === 7) {
    sectionIndex = 3;
  } else if (props.currentIndex === 8) {
    sectionIndex = 4;
  }
  // console.log(" sectionIndex", sectionIndex);
  return (
    <>
      {sectionIndex >= 1 && props.currentIndex < 8 && (
        <header className="pt-8 lg:pt-20 pb-10 border-gray border-b">
          <span className="font-body text-primary">
            {props.sections[sectionIndex].label}
          </span>
          <h1 className="font-headline text-primary pb-4">
            {props.sections[sectionIndex].title}
          </h1>
          <p className="font-body text-black" dangerouslySetInnerHTML={{ __html: props.sections[sectionIndex].desc }}>

          </p>
        </header>
      )}
    </>
  );
};

export { SurveyHeader };
